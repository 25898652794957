import { RefObject } from 'react';
import { useLocation } from 'react-router-dom';
import useBreakpointRange from 'src/hooks/useBreakpointRange';

import ConditionalWrap from '../ConditionalWrap';

import * as S from './styles';

interface Props {
  linkList: {
    exact?: boolean;
    hideCondition?: boolean;
    link: string;
    onClick?: () => void;
    title: string;
  }[];
  linksRef?: RefObject<HTMLDivElement>;
  navTitle: string;
}

const SecondaryNavigation = ({ linkList, linksRef, navTitle }: Props) => {
  const location = useLocation();
  const { isInDesktopBreakpointRange } = useBreakpointRange();

  return (
    <S.SecondaryNavigation.Root>
      <ConditionalWrap
        condition={isInDesktopBreakpointRange}
        wrapper={children => (
          <S.SecondaryNavigation.Wrapper>
            <S.SecondaryNavigation.Text>{navTitle}</S.SecondaryNavigation.Text>
            {children}
          </S.SecondaryNavigation.Wrapper>
        )}
      >
        <S.SecondaryNavigation.Links
          ref={linksRef}
          data-cy="secondary-nav-links"
        >
          {linkList.map(link => {
            const isExact = link.exact !== undefined ? link.exact : false;
            if (link.hideCondition !== undefined && link.hideCondition) return null;
            return (
              <S.SecondaryNavigation.Link
                key={link.link}
                className={
                  (isExact && location.pathname === link.link.split('?')[0]) ||
                  (!isExact && location.pathname.includes(link.link.split('?')[0]))
                    ? 'is-selected'
                    : undefined
                }
                data-label={link.title}
                onClick={link.onClick}
                to={link.link}
              >
                <S.SecondaryNavigation.LinkText>{link.title}</S.SecondaryNavigation.LinkText>
              </S.SecondaryNavigation.Link>
            );
          })}
        </S.SecondaryNavigation.Links>
      </ConditionalWrap>
    </S.SecondaryNavigation.Root>
  );
};

export default SecondaryNavigation;
