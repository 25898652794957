import Button from 'src/components/Buttons/Button';
import TextField from 'src/components/FormFields/TextField';
import UnstyledButton from 'src/components/UnstyledButton';
import { MOBILE_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const BodyText = styled.div`
  ${TEXT_STYLES.bodyCopyLarge}
  color: var(--gray-500);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;

  ${MOBILE_BREAKPOINT_RANGE} {
    flex-direction: column;
  }
`;

interface CheckButtonProps {
  $checked: boolean;
}

export const CheckButton = styled(Button)<CheckButtonProps>`
  ${({ $checked }) =>
    $checked
      ? `
        background-color: var(--purple-25);
        color: var(--purple-400);
        border-color: var(--purple-400);

        &:hover {
          background-color: var(--purple-50);
        }
      `
      : ''}
`;

export const InputField = styled(TextField)`
  input {
    margin-top: 1rem;
  }
`;

export const LoginLink = styled(UnstyledButton)`
  color: var(--purple-400);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SmallText = styled.div`
  ${TEXT_STYLES.bodyCopySmall}
  color: var(--gray-800);
  margin-top: 0.5rem;
`;

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export const Title = styled.h1`
  ${TEXT_STYLES.headlineStandardLevel2BoldRegular}
  margin: 0 0 0.5rem;
`;
