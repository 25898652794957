import { GridNode } from '@react-types/grid';
import clsx from 'clsx';
import { isValidElement, useRef } from 'react';
import { mergeProps, useFocusRing, useTableColumnHeader } from 'react-aria';
import { ColumnProps, TableState } from 'react-stately';

import * as S from './styles';

interface Props {
  column: GridNode<object>;
  isCentered?: boolean;
  state: TableState<object>;
}

const HeaderCell = ({ column, isCentered = false, state }: Props) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const { columnHeaderProps } = useTableColumnHeader({ node: column }, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const arrowIcon =
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unnecessary-condition
    state.sortDescriptor &&
    state.sortDescriptor.direction === 'ascending' &&
    state.sortDescriptor.column === column.key ? (
      <S.ArrowUp $isActive={state.sortDescriptor.column === column.key} />
    ) : (
      <S.ArrowDown
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unnecessary-condition
        $isActive={state.sortDescriptor !== undefined && state.sortDescriptor.column === column.key}
      />
    );

  const isSortable = (column.props as ColumnProps<object>).allowsSorting === true;
  const isNumberCell =
    isSortable &&
    isValidElement(column.rendered) &&
    typeof column.rendered.props === 'object' &&
    (column.rendered.props as { alignRight?: boolean }).alignRight === true;

  return (
    <S.HeaderCell
      $isEmpty={column.rendered === false}
      $rightAligned={isNumberCell}
      {...mergeProps(columnHeaderProps, focusProps)}
      ref={ref}
      className={clsx(
        { 'is-focused': isFocusVisible },
        { 'is-sortable': isSortable },
        { 'is-wide': column.colspan !== undefined && column.colspan > 1 }
      )}
      colSpan={column.colspan}
    >
      <S.CellWrapper $isCentered={isCentered}>
        {column.rendered}
        {isSortable && (
          <S.ArrowWrapper
            $rightAligned={isNumberCell}
            aria-hidden="true"
          >
            {arrowIcon}
          </S.ArrowWrapper>
        )}
      </S.CellWrapper>
    </S.HeaderCell>
  );
};

export default HeaderCell;
