import { ReactNode, useRef } from 'react';
import { AriaTableProps, useTable } from 'react-aria';
import { TableStateProps, useTableState } from 'react-stately';

import BodyCell from './BodyCell';
import BodyCheckboxCell from './BodyCheckboxCell';
import BodyRow from './BodyRow';
import HeaderCell from './HeaderCell';
import HeaderRow from './HeaderRow';
import HeaderSelectAllCell from './HeaderSelectAllCell';
import RowGroup from './RowGroup';
import * as S from './styles';

interface Props extends AriaTableProps, TableStateProps<object> {
  centeredIndexes?: number[];
  className?: string;
  hasLinkedRows?: boolean;
  onRowClick?: (key: string) => void;
  renderEmptyState?: () => ReactNode;
}

const Table = ({
  centeredIndexes,
  className,
  hasLinkedRows = false,
  onRowClick,
  renderEmptyState,
  ...tableProps
}: Props) => {
  const { selectionBehavior, selectionMode } = tableProps;
  const state = useTableState({
    ...tableProps,
    showSelectionCheckboxes: selectionMode === 'multiple' && selectionBehavior !== 'replace'
  });

  const ref = useRef<HTMLTableElement>(null);
  const { collection } = state;
  const { gridProps } = useTable(tableProps, state, ref);

  /**
   * Wrapper around react-spectrum's TableView component to work around a bug
   * with handling of the Space key:
   * https://github.com/adobe/react-spectrum/issues/1214
   * The bug is caused by code in
   * https://github.com/adobe/react-spectrum/blob/main/packages/@react-aria/selection/src/useTypeSelect.ts#L92
   * that implements "type-ahead" functionality. This workaround
   * effectively disables that functionality, in a pretty hacky way.
   */

  return (
    <div
      className={className}
      onKeyDownCapture={event => {
        // When we capture a keydown event for the space key,
        // replace the key property with another value so that react-spectrum
        // does not trigger its special logic for the space key
        // (assuming we don't really that need, anyway).
        // The new value is a breakable space, which is similar to
        // the original space, just in case any other code relies on this event.
        if (event.key === ' ') {
          // breakable space to keep wrapping behavior with any inputted values
          event.key = '&#x20';
        }
      }}
    >
      <S.TableWrapper className={className}>
        <S.Table
          {...gridProps}
          ref={ref}
        >
          <RowGroup type="thead">
            {collection.headerRows.map(headerRow => (
              <HeaderRow
                key={headerRow.key}
                item={headerRow}
                state={state}
              >
                {Array.from(headerRow.childNodes)
                  .filter(column => (column.props as { exclude?: boolean }).exclude !== true)
                  .map(column =>
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    column.props.isSelectionCell === true ? (
                      <HeaderSelectAllCell
                        key={column.key}
                        column={column}
                        state={state}
                      />
                    ) : (
                      <HeaderCell
                        key={column.key}
                        column={column}
                        isCentered={centeredIndexes?.includes(column.index ?? -1)}
                        state={state}
                      />
                    )
                  )}
              </HeaderRow>
            ))}
          </RowGroup>
          <RowGroup type="tbody">
            {Array.from(collection.body.childNodes).length > 0
              ? Array.from(collection.body.childNodes).map(row => (
                  <BodyRow
                    key={row.key}
                    hasLinks={hasLinkedRows}
                    item={row}
                    onRowClick={onRowClick}
                    state={state}
                  >
                    {Array.from(row.childNodes)
                      .filter(cell => (cell.props as { exclude?: boolean }).exclude !== true)
                      .map(cell =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        cell.props.isSelectionCell === true ? (
                          <BodyCheckboxCell
                            key={cell.key}
                            cell={cell}
                            state={state}
                            {...cell.props}
                          />
                        ) : (
                          <BodyCell
                            key={cell.key}
                            cell={cell}
                            isCentered={centeredIndexes?.includes(cell.index ?? -1)}
                            state={state}
                            {...cell.props}
                          />
                        )
                      )}
                  </BodyRow>
                ))
              : renderEmptyState
                ? renderEmptyState()
                : null}
          </RowGroup>
        </S.Table>
      </S.TableWrapper>
    </div>
  );
};

export default Table;
