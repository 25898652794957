import ComboBox from 'src/components/FormFields/ComboBox';
import Spinner from 'src/components/Spinner';
import UnstyledButton from 'src/components/UnstyledButton';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from '../../../../constants';

export const AllOption = styled.div`
  color: var(--gray-500);
`;

export const CheckboxWrapper = styled.div`
  align-items: center;
  column-gap: 1.25rem;
  display: flex;
`;

export const EmptyState = styled.div`
  ${TEXT_STYLES.formsLabelRegular}
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
`;

interface ExpandCollapseButtonProps {
  $isExpanded: boolean;
}

export const ExpandCollapseButton = styled(UnstyledButton)<ExpandCollapseButtonProps>`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: start;

  svg {
    width: 2.25rem;
    height: 2.25rem;
    fill: var(--gray-800);
  }

  ${({ $isExpanded }) => {
    if ($isExpanded) {
      return `
        align-items: start;
        margin-bottom: 1.5rem;
      `;
    } else {
      return `
        align-items: center;
      `;
    }
  }}
`;

export const ExpandCollapseDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

interface ExpandCollapseTextWrapperProps {
  $isExpanded: boolean;
}

export const ExpandCollapseTextWrapper = styled.div<ExpandCollapseTextWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ $isExpanded }) => {
    if ($isExpanded) {
      return `
      margin-top: 0.39rem;
      `;
    }
  }}
`;

export const ExpandCollapseTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin: 0;
`;

export const FiltersAndActionsWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const OnVioletWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  padding: 1rem;
  grid-column: span 2;
  height: 3rem;
`;

export const SmallSpinner = styled(Spinner)`
  fill: none !important;
`;

export const TableWrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  padding: 1.5rem;
  margin-bottom: 1rem;
`;

export const WideComboBox = styled(ComboBox)`
  ${DESKTOP_BREAKPOINT_RANGE} {
    grid-column: span 2;
  }
`;
