import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxProps,
  AriaSearchFieldProps,
  VisuallyHidden
} from 'react-aria';
import { Cell, Column, Item, Row, SortDescriptor, TableBody, TableHeader } from 'react-stately';
import Button from 'src/components/Buttons/Button';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import PopoverTrigger from 'src/components/PopoverTrigger';
import Table from 'src/components/Table';
import Filters from 'src/components/Table/Filters';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import UnstyledButton from 'src/components/UnstyledButton';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { LANGUAGES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';

import Spinner from '../../../../components/Spinner';
import useOpenErrorModalDialog from '../../../../hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from '../../../../hooks/useOpenSignedOutModalDialog';
import useUser from '../../../../hooks/useUser';
import { downloadCsv } from '../../../../utils/downloadCsv';
import TableEmptyState from '../../components/TableEmptyState';
import useGetOrganizationUsers, { OrganizationFilters } from '../../hooks/useGetOrganizationUsers';
import { Organization, OrganizationUser } from '../../utils';

import * as S from './styles';

const CulturalCompetencies = () => {
  const openErrorModalDialog = useOpenErrorModalDialog();

  const openSignedOutModalDialog = useOpenSignedOutModalDialog();

  const { bearerToken, user } = useUser();
  const featureFlags = useFeatureFlags();

  const organizationId = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization_id;
  const organizationName = user.organization_memberships.find(
    membership => membership.member_role === 'superuser'
  )?.organization.name;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchByNameValue, setSearchByNameValue] = useState('');
  const [selectedCulturalCompetencyBadgesFilter, setSelectedCulturalCompetencyBadgesFilter] =
    useState<string[] | null>(null);
  const [selectedLanguageFilter, setSelectedLanguageFilter] = useState<string | null>();
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: 'full_name',
    direction: 'ascending'
  });
  const [filters, setFilters] = useState<OrganizationFilters>({
    completed_onboarding: true,
    is_clinical: true
  });
  const { isFetchingOrgUsers, totalUserPages, updateOrganizationFilters, users } =
    useGetOrganizationUsers(filters, organizationId);

  const [isExporting, setIsExporting] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [canExportCSV, setCanExportCSV] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    const newFilters: OrganizationFilters = {};

    newFilters.clinical = true;
    newFilters.completed_onboarding = true;

    if (selectedCulturalCompetencyBadgesFilter) {
      newFilters.has_scored = selectedCulturalCompetencyBadgesFilter;
    }
    if (typeof selectedLanguageFilter === 'string') {
      newFilters.language = selectedLanguageFilter;
    }
    newFilters.order_by = {
      column: sortDescriptor.column as string,
      dir: sortDescriptor.direction === 'ascending' ? 'asc' : 'desc'
    };
    if (searchByNameValue) {
      newFilters.full_name = searchByNameValue;
    }
    newFilters.page = currentPage;
    setFilters(newFilters);
  }, [
    currentPage,
    searchByNameValue,
    selectedCulturalCompetencyBadgesFilter,
    selectedLanguageFilter,
    sortDescriptor
  ]);

  useEffect(() => {
    updateOrganizationFilters(filters);
  });

  useEffect(() => {
    if (user.organization_memberships.length > 0) {
      setOrganization(
        user.organization_memberships.find(org => org.member_role === 'superuser')?.organization
      );
    }
  }, [user.organization_memberships]);

  useEffect(() => {
    if (!organization) {
      return;
    }
    setCanExportCSV(organization.permissions.includes('users.export'));
  }, [organization, featureFlags]);

  const handleExportCsvButtonPress = async () => {
    if (!organization) {
      return;
    }

    setIsExporting(true);

    const educationExportUrl: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${organization.id}/users/export?integrated_license=true`;

    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    try {
      const response = await fetch(educationExportUrl, options);

      let isSignedOut = false;

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          isSignedOut = true;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      if (isSignedOut) return;

      const date = new Date();
      const formattedDate = date
        .toLocaleString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        })
        .replace(/\//g, '-');
      await downloadCsv(
        response,
        `Violet_CulturalCompetencies_${organizationName?.replaceAll(' ', '')}_${formattedDate}.csv`
      );

      setIsExporting(false);
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
      setIsExporting(false);
    }
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    if (communities.length === 0) {
      setSelectedCulturalCompetencyBadgesFilter(null);
    } else {
      setSelectedCulturalCompetencyBadgesFilter(communities);
    }
  };

  const handleSearchByNameFieldChange: AriaSearchFieldProps['onChange'] = value => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchByNameValue(value);
    }, 500);
  };

  const handleLanguagesSelectionChange: AriaComboBoxProps<object>['onSelectionChange'] = key => {
    setSelectedLanguageFilter(key as string);
  };

  const mappedBadgeCells = (badges: NonNullable<OrganizationUser['badges']>) => {
    const bipocBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'bipoc');
    const lgbqBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'lgbq');
    const tgncBenchmark = badges.find(benchmark => benchmark.community?.toLowerCase() === 'tgnc');
    return [bipocBenchmark, lgbqBenchmark, tgncBenchmark].map(badge =>
      badge ? (
        <PopoverTrigger
          key={badge.title}
          content={
            <S.Badges.PopoverText>
              <S.Badges.Title>{badge.title}</S.Badges.Title>
              {isNonEmptyString(badge.assessedOn) && (
                <S.Badges.AssessedOn>Assessed on: {badge.assessedOn}</S.Badges.AssessedOn>
              )}
              {isNonEmptyString(badge.dateEarned) && (
                <S.Badges.DateEarned>Date earned: {badge.dateEarned}</S.Badges.DateEarned>
              )}
            </S.Badges.PopoverText>
          }
          theme="light"
        >
          <UnstyledButton>
            <S.Badges.Badge
              alt={badge.imageAltText}
              src={badge.imageSrc}
              tabIndex={0}
            />
          </UnstyledButton>
        </PopoverTrigger>
      ) : (
        <div>&nbsp;</div>
      )
    );
  };

  return (
    <>
      <PageTitle
        title="Cultural competencies"
        titleVariant="h1"
      />
      <Filters
        actionButton={
          canExportCSV ? (
            <Button
              data-cy="export-csv-button"
              isLoading={isExporting}
              onPress={handleExportCsvButtonPress}
              size="regular"
              trailingIcon={ExportIcon}
            >
              Export CSV
            </Button>
          ) : undefined
        }
      >
        <SearchField
          aria-label="Search by user name"
          defaultValue={searchByNameValue}
          onChange={handleSearchByNameFieldChange}
          placeholder="User name"
        />
        <ComboBox
          aria-label="Languages"
          icon={SearchIcon}
          onSelectionChange={handleLanguagesSelectionChange}
          placeholder="Language"
          selectedKey={selectedLanguageFilter}
        >
          {LANGUAGES.map(language => (
            <Item key={language}>{language}</Item>
          ))}
        </ComboBox>
        <CommunityFilter filterLabelText="Community Benchmark">
          <CheckboxGroup
            aria-labelledby="filter-label"
            direction="horizontal"
            onChange={handleFilterByCommunityChange}
            size="regular"
            value={
              selectedCulturalCompetencyBadgesFilter === null
                ? []
                : selectedCulturalCompetencyBadgesFilter
            }
          >
            <GroupCheckbox
              data-cy="community-filter-bipoc"
              value="BIPOC"
            >
              BIPOC
            </GroupCheckbox>
            <GroupCheckbox
              data-cy="community-filter-lgbq"
              value="LGBQ"
            >
              LGBQ
            </GroupCheckbox>
            <GroupCheckbox
              data-cy="community-filter-tgnc"
              value="TGNC"
            >
              TGNC
            </GroupCheckbox>
          </CheckboxGroup>
        </CommunityFilter>
      </Filters>

      {isFetchingOrgUsers ? (
        <S.SpinnerWrapper>
          <Spinner />
        </S.SpinnerWrapper>
      ) : users.length === 0 ? (
        <TableEmptyState />
      ) : (
        <>
          <Table
            aria-label="Table listing all providers and the badges they've each obtained"
            data-cy="cultural-competencies-table"
            onSortChange={({ column, direction }) => {
              setSortDescriptor({ column, direction });
            }}
            sortDescriptor={sortDescriptor}
          >
            <TableHeader>
              <Column
                key="full_name"
                allowsSorting
              >
                NAME
              </Column>
              <Column
                key="email"
                allowsSorting
              >
                EMAIL
              </Column>
              <Column>LANGUAGES</Column>
              <Column>
                <S.BenchmarksColumn>
                  <div key="BIPOC">BIPOC</div>
                  <div key="LGBQ">LGBQ</div>
                  <div key="TGNC">TGNC</div>
                </S.BenchmarksColumn>
              </Column>
            </TableHeader>
            <TableBody>
              {users.map(user => (
                <Row
                  key={user.email}
                  data-cy="my-org-cultural-competence-user-row"
                >
                  <Cell>
                    <S.TruncateLongText title={user.name}>{user.name}</S.TruncateLongText>
                  </Cell>
                  <Cell>
                    <S.TruncateLongText title={user.email}>{user.email}</S.TruncateLongText>
                  </Cell>
                  <Cell>
                    <S.TruncateLongText title={user.languages.join(', ')}>
                      {user.languages.join(', ')}
                    </S.TruncateLongText>
                  </Cell>
                  <Cell>
                    {user.badges ? (
                      <S.Badges.Root>{mappedBadgeCells(user.badges)}</S.Badges.Root>
                    ) : (
                      <VisuallyHidden>No benchmarks</VisuallyHidden>
                    )}
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
          <Pagination
            currentPage={currentPage}
            setPage={setCurrentPage}
            totalPages={totalUserPages}
          />
        </>
      )}
    </>
  );
};

export default CulturalCompetencies;
