import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { generateOrganizationUser, generateUser, OrganizationUser } from '../utils';

import useApiRequest from './useApiRequest';

export interface OrganizationFilters {
  clinical?: boolean; // V2
  communities?: string[] | null; // V1 (migrate -> has_scored)
  completed_onboarding?: boolean; // V2
  full_name?: string; // V2
  has_scored?: string[] | null; // V2
  is_clinical?: boolean; // V1 (migrate -> clinical)
  language?: string | null;
  member_role?: 'member' | 'superuser'; // V2
  order_by?: {
    column: string | undefined;
    dir: 'asc' | 'desc';
  };
  page?: number;
  per_page?: number;
  status?: 'activated' | 'created'; // V2
  user_name?: string; // V1 (migrate -> full_name)
}

export const USER_TYPES = ['all', 'clinical', 'non-clinical'];

interface UseGetOrganizationUsers {
  (
    filters: OrganizationFilters,
    organizationId?: string
  ): {
    isFetchingOrgUsers: boolean;
    refetchOrganizationUsers: () => Promise<void>;
    setUsers: Dispatch<SetStateAction<OrganizationUser[]>>;
    totalUserPages: number;
    updateOrganizationFilters: (newFilters: OrganizationFilters) => void;
    users: OrganizationUser[];
  };
}

const useGetOrganizationUsers: UseGetOrganizationUsers = (filters, organizationId) => {
  const { getRequest, reportError } = useApiRequest();

  const [totalUserPages, setTotalUserPages] = useState(1);
  const [users, setUsers] = useState<OrganizationUser[]>([]);
  const [isFetchingOrgUsers, setIsFetchingOrgUsers] = useState(false);
  const [query, setQuery] = useState<OrganizationFilters>(filters);

  const fetchOrganizationUsers: () => Promise<void> = useCallback(async () => {
    setIsFetchingOrgUsers(true);

    const searchQuery = { ...query };
    delete searchQuery.order_by;
    delete searchQuery.communities;
    delete searchQuery.has_scored;
    let params = new URLSearchParams(searchQuery as Record<string, string>).toString();

    // v1 'communities'
    if (query.communities) {
      params += `${params ? '&' : ''}${query.communities
        .map(community => `communities[]=${community.toLowerCase()}`)
        .join('&')}`;
    }
    // v2 'has_scored'(this is still communities)
    if (query.has_scored) {
      params += `${params ? '&' : ''}${query.has_scored
        .map(community => `has_scored[]=${community.toLowerCase()}`)
        .join('&')}`;
    }

    if (query.order_by) {
      params += `${params ? '&' : ''}order_by[column]=${query.order_by.column}&order_by[dir]=${
        query.order_by.dir
      }`;
    }

    let baseUrl = '';
    if (organizationId !== undefined) {
      baseUrl = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${organizationId}/users`;
    } else {
      baseUrl = `${process.env.REACT_APP_API_BASE_PATH}/users/dashboard/organizations`;
    }
    const url: RequestInfo = `${baseUrl}${params ? `?${params}` : ''}`;

    try {
      if (organizationId !== undefined) {
        const { data, meta } = (await getRequest(url)) as {
          data: User[] | undefined;
          meta?: APIUsersDashboardOrganizations['meta'];
        };
        setTotalUserPages(meta !== undefined ? Number(meta.total_pages) : 1);
        setUsers(
          data !== undefined ? data.map(fields => generateUser(fields, organizationId)) : []
        );
      } else {
        const { data } = (await getRequest(url)) as {
          data: APIUsersDashboardOrganizations['data'] | undefined;
        };
        setUsers(data !== undefined ? data.map(fields => generateOrganizationUser(fields)) : []);
      }
    } catch (error) {
      reportError(error);
    }

    setIsFetchingOrgUsers(false);
  }, [getRequest, reportError, query, organizationId]);

  useEffect(() => {
    (async () => {
      await fetchOrganizationUsers();
    })();
  }, [fetchOrganizationUsers]);

  const updateOrganizationFilters = (newFilters: OrganizationFilters) => {
    setQuery(newFilters);
  };

  const refetchOrganizationUsers = useCallback(async () => {
    await fetchOrganizationUsers();
  }, [fetchOrganizationUsers]);

  return {
    isFetchingOrgUsers,
    refetchOrganizationUsers,
    setUsers,
    totalUserPages,
    updateOrganizationFilters,
    users
  };
};

export default useGetOrganizationUsers;
