import { Link } from 'react-router-dom';
import {
  BREAKPOINT,
  DESKTOP_BREAKPOINT_RANGE,
  MOBILE_BREAKPOINT_RANGE,
  TEXT_STYLES
} from 'src/constants';
import { MOBILE_HEADER_HEIGHT, SECONDARY_NAVIGATION_HEIGHT } from 'src/pages/Dashboard/constants';
import styled from 'styled-components';

import { PURPLE_400_COLOR } from '../GlobalStyle';

const MED_BREAKPOINT = '1200';
const MED_BREAKPOINT_RANGE = `@media (min-width: ${BREAKPOINT}px) and (max-width: ${MED_BREAKPOINT}px)`;

export const SecondaryNavigation = {
  Link: styled(Link)`
    ${TEXT_STYLES.bodyCopyRegular}
    align-items: center;
    justify-content: center;
    text-decoration: unset;
    color: var(--gray-400);
    transition: color 0.3s;

    ${MOBILE_BREAKPOINT_RANGE} {
      display: inline-flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;
      padding: 0 1rem;
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      display: flex;
      flex-grow: 1;
    }

    ${MED_BREAKPOINT_RANGE} {
      position: relative;
    }

    &::before {
      content: attr(data-label);
      font-weight: 500;
      visibility: hidden;
    }

    &:hover {
      color: var(--gray-600);
    }

    &.is-selected {
      font-weight: 500;
      color: var(--purple-400);
      background-image: linear-gradient(to top, ${PURPLE_400_COLOR} 0.3125rem, transparent 0);
      background-clip: content-box;
    }
  `,
  Links: styled.div`
    height: 100%;

    ${DESKTOP_BREAKPOINT_RANGE} {
      display: flex;
      margin-left: auto;
      flex-grow: 1;
      padding: 0 var(--space-9);
    }

    @media (width >= 1200px) {
      max-width: calc(100% - (2 * var(--space-9)) - 200px);
    }

    ${MOBILE_BREAKPOINT_RANGE} {
      position: relative;
      white-space: nowrap;
      overflow-x: auto;

      scrollbar-width: none;

      ::-webkit-scrollbar {
        height: 0;
      }
    }
  `,
  LinkText: styled.div`
    position: absolute;
    align-self: center;

    ${MED_BREAKPOINT_RANGE} {
      padding: 0 1rem;
    }
  `,
  Root: styled.nav`
    position: sticky;
    background-color: white;
    z-index: 2;

    ${MOBILE_BREAKPOINT_RANGE} {
      top: ${MOBILE_HEADER_HEIGHT}px;
      height: ${SECONDARY_NAVIGATION_HEIGHT.mobile}px;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: none;

        /* See https://css-tricks.com/thing-know-gradients-transparent-black/ */
        background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 15%);
      }
    }

    ${DESKTOP_BREAKPOINT_RANGE} {
      top: 0;
      height: ${SECONDARY_NAVIGATION_HEIGHT.desktop}px;
      box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.05);
    }
  `,
  Text: styled.div`
    font-size: 2.5625rem;
    font-weight: 500;
    color: var(--purple-400);
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    max-width: 1420px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--space-9);

    & > div:last-child {
      display: contents;
    }
  `
};
