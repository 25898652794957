import styled from 'styled-components';

interface DataContainerProps {
  $hideBorder: boolean;
}

export const DataContainer = styled.div<DataContainerProps>`
  background-color: var(--white-light);
  display: grid;
  gap: 1rem;

  ${({ $hideBorder }) => {
    if ($hideBorder) {
      return `
        margin-bottom: 1rem;
      `;
    } else {
      return `
        border: 1px solid var(--gray-200);
        border-radius: 1rem;
        padding: 1rem;
        margin-bottom: 2rem;
    `;
    }
  }}
`;
