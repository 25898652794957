import FilterDropdown from 'src/components/FilterDropdown';
import Spinner from 'src/components/Spinner';
import styled from 'styled-components';

import { TEXT_STYLES } from '../../../../constants';

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 1.5rem;
`;

export const ChartTitle = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin-bottom: 1.5rem;
`;

export const ChartWrapper = styled.div`
  border: 1px solid var(--gray-200);
  border-radius: 1rem;
  padding: 2rem 1.5rem;
`;

export const Filters = styled(FilterDropdown)`
  margin-bottom: 1rem;
`;

export const LanguageDescription = styled.div`
  ${TEXT_STYLES.bodyCopyRegular}
  margin-bottom: 2rem;
`;

export const LanguagePercentage = styled.span`
  margin-right: 0.25rem;
`;

export const SmallSpinner = styled(Spinner)`
  fill: none !important;
`;
