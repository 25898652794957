import Table from 'src/components/Table';
import styled from 'styled-components';

export const LocationTable = styled(Table)`
  tr {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  tbody {
    display: grid;
    gap: 0.5rem;

    tr {
      border-radius: 0.5rem;
      height: 3.75rem; /* 60px */
      align-items: center;
    }
  }

  tr th:nth-child(2) {
    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(3) {
    background-color: var(--light-purple-50);
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(4) {
    background-color: var(--light-purple-50);

    > div {
      justify-content: end;
    }
  }

  tr th:nth-child(5) {
    background-color: var(--light-purple-50);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    > div {
      justify-content: end;
    }
  }
`;
