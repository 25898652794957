import { ReactComponent as ShowLessIcon } from '@material-design-icons/svg/round/expand_less.svg';
import { ReactComponent as ShowMoreIcon } from '@material-design-icons/svg/round/expand_more.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import capitalize from 'lodash/capitalize';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Key, useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaCheckboxProps,
  AriaComboBoxProps,
  useFilter
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item, SortDescriptor, SortDirection } from 'react-stately';
import FilterDropdown from 'src/components/FilterDropdown';
import Checkbox from 'src/components/FormFields/Checkbox';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import MultiComboBox from 'src/components/FormFields/MultiComboBox';
import PageTitle from 'src/components/PageTitle';
import Spinner from 'src/components/Spinner';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import useUser from 'src/hooks/useUser';
import { NPI_SPECIALTIES, STATES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { loadNetworkOrgMemberName } from 'src/utils/loadNetworkOrgMemberName';

import useGetNetworkLocations, {
  NetworkLocationsFilters
} from '../../hooks/useGetNetworkLocations';
import useGetNetworkOrgMembers from '../../hooks/useGetNetworkOrgMembers';
import useGetNetworkOverview from '../../hooks/useGetNetworkOverview';
import useGetNetworkProviders, { NetworkProviderFilters } from '../../hooks/useGetNetworkProviders';
import { NetworkOrganizationMember } from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';
import { filtersObjectToParams, networkSearchToFilters, sortParams } from '../networkFilters';

import { NetworkOverviewSection } from './NetworkOverview';
import * as S from './styles';
import { NetworkMembersTable } from './Tables/NetworkMembersTable';
import { NetworkProvidersTable } from './Tables/NetworkProvidersTable';
import { ServiceAreasTable } from './Tables/ServiceAreasTable';

const Inclusivity = () => {
  const { bearerToken } = useUser();
  const { contains } = useFilter({ sensitivity: 'base' });
  const isVioEnabled = useFeatureFlagEnabled('verified_inclusive_org');
  const [showServiceArea, setShowServiceArea] = useState(true);
  const [showProviderOrganizations, setShowProviderOrganizations] = useState(true);
  const [showProviders, setShowProviders] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orgName, setOrgName] = useState<string | null>(null);

  /* -------------------- FILTERS AND URL PARAM STATES -------------------- */
  const [currentPageProviders, setCurrentPageProviders] = useState<number>(
    searchParams.get('page_providers') !== null ? Number(searchParams.get('page_providers')) : 1
  );
  const [sortDescriptorProviders, setSortDescriptorProviders] = useState<SortDescriptor>({
    column: searchParams.get('order_by_providers[column]') ?? 'full_name',
    direction:
      searchParams.get('order_by_providers[dir]') === null
        ? 'ascending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_providers[dir]')!
          ] as SortDirection)
  });
  const [currentPageLocations, setCurrentPageLocations] = useState<number>(
    searchParams.get('page_locations') !== null ? Number(searchParams.get('page_locations')) : 1
  );
  const [sortDescriptorLocations, setSortDescriptorLocations] = useState<SortDescriptor>({
    column: searchParams.get('order_by_locations[column]') ?? 'provider_count',
    direction:
      searchParams.get('order_by_locations[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_locations[dir]')!
          ] as SortDirection)
  });
  const [currentPageOrganizations, setCurrentPageOrganizations] = useState<number>(
    searchParams.get('page_organizations') !== null
      ? Number(searchParams.get('page_organizations'))
      : 1
  );
  const [sortDescriptorOrganizations, setSortDescriptorOrganizations] = useState<SortDescriptor>({
    column: searchParams.get('order_by_organizations[column]') ?? 'on_violet',
    direction:
      searchParams.get('order_by_organizations[dir]') === null
        ? 'descending'
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by_organizations[dir]')!
          ] as SortDirection)
  });
  const [stateSearchValue, setStateSearchValue] = useState<string>('');
  const [selectedStates, setSelectedStates] = useState<Set<Key>>(
    new Set(searchParams.get('states[]')?.split(',') ?? [])
  );
  const [selectedOrganizationsFilter, setSelectedOrganizationsFilter] = useState<(string | null)[]>(
    searchParams.get('organizations[]')?.split(',') ?? []
  );
  const [activeProviderOrgsChecked, setActiveProviderOrgsChecked] = useState<boolean>(
    searchParams.get('org_is_on_violet') === 'true'
  );
  const [verifiedInclusiveChecked, setVerifiedInclusiveChecked] = useState<boolean>(
    searchParams.get('verified_inclusive') === 'true'
  );
  const draftState = useRef<{
    benchmark_communities: string[];
    org_is_on_violet: boolean;
    organizations: (string | null)[];
    specialties: (string | null)[];
    states: Set<Key>;
    verified_inclusive: boolean;
  }>({
    benchmark_communities: searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    org_is_on_violet: searchParams.get('org_is_on_violet') === 'true',
    organizations: searchParams.get('organizations[]')?.split(',') ?? [],
    specialties: searchParams.get('specialties[]')?.split(',') ?? [],
    states: new Set(searchParams.get('states[]')?.split(',') ?? []),
    verified_inclusive: searchParams.get('verified_inclusive') === 'true'
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .flatMap(([key, value]) => value.split(',').map(v => networkSearchToFilters(key, v) as Key))
    )
  );

  /* -------------------- HANDLERS -------------------- */
  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    draftState.current.benchmark_communities = [...communities].sort();
  };

  const handleOrganizationsFilterChange: AriaComboBoxProps<
    NetworkOrganizationMember['id']
  >['onSelectionChange'] = value => {
    draftState.current.organizations =
      value !== null && value.toString().trim() !== '' ? [value as string] : [];
  };

  const handleSpecialtiesFilterChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    draftState.current.specialties = [value as string];
  };

  const handleStateSearchChange: AriaComboBoxProps<string>['onInputChange'] = value => {
    setStateSearchValue(value);
  };

  const handleSelectedStatesChange: AriaComboBoxProps<string>['onSelectionChange'] = (
    value: Key | null
  ) => {
    if (value === null) return;

    if (value === 'all') {
      draftState.current.states = new Set();
    } else {
      const currentStates = Array.from(selectedStates);
      const index = currentStates.findIndex(state => state === value);
      if (index === -1) {
        currentStates.push(value as string);
      } else {
        currentStates.splice(index, 1);
      }
      draftState.current.states = new Set(currentStates);
    }

    setStateSearchValue('');
  };

  const handleActiveProviderOrgsFilterChange: AriaCheckboxProps['onChange'] = value => {
    draftState.current.org_is_on_violet = value;
    setActiveProviderOrgsChecked(value);
  };

  const handleVerifiedInclusiveFilterChange: AriaCheckboxProps['onChange'] = value => {
    draftState.current.verified_inclusive = value;
    setVerifiedInclusiveChecked(value);
  };

  const handleProvidersTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorProviders({ column, direction });
    handleSetPageProviders(1);
    if (column !== undefined) {
      setSearchParams(params => {
        params.set('order_by_providers[column]', column.toString());
        params.set('order_by_providers[dir]', direction === 'ascending' ? 'asc' : 'desc');
        params.set('page_providers', '1');
        return params;
      });
    }
  };

  const handleLocationsTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorLocations({ column, direction });
    handleSetPageLocations(1);
    if (column !== undefined) {
      setSearchParams(params => {
        params.set('order_by_locations[column]', column.toString());
        params.set('order_by_locations[dir]', direction === 'ascending' ? 'asc' : 'desc');
        params.set('page_locations', '1');
        return params;
      });
    }
  };

  const handleOrganizationsTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    setSortDescriptorOrganizations({ column, direction });
    handleSetPageOrganizations(1);
    if (column !== undefined) {
      setSearchParams(params => {
        params.set('order_by_organizations[column]', column.toString());
        params.set('order_by_organizations[dir]', direction === 'ascending' ? 'asc' : 'desc');
        params.set('page_organizations', '1');
        return params;
      });
    }
  };

  const handleSetPageProviders = (page: number) => {
    setCurrentPageProviders(page);
    setSearchParams(params => {
      params.set('page_providers', page.toString());
      return params;
    });
  };

  const handleSetPageLocations = (page: number) => {
    setCurrentPageLocations(page);
    setSearchParams(params => {
      params.set('page_locations', page.toString());
      return params;
    });
  };

  const handleSetPageOrganizations = (page: number) => {
    setCurrentPageOrganizations(page);
    setSearchParams(params => {
      params.set('page_organizations', page.toString());
      return params;
    });
  };

  /* -------------------- API CALLS -------------------- */
  // OVERVIEW
  const overviewSearchParams = {
    'benchmark_communities[]': searchParams.get('benchmark_communities[]')?.split(',') ?? [],
    org_is_on_violet: searchParams.get('org_is_on_violet') === 'true' ? true : undefined,
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? [],
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? [],
    'states[]': searchParams.get('states[]')?.split(',') ?? [],
    verified_inclusive: searchParams.get('verified_inclusive') === 'true'
  };
  const {
    isFetching: isFetchingOverview,
    networkOverview,
    updateNetworkOverviewFilters
  } = useGetNetworkOverview(overviewSearchParams);

  // PROVIDERS
  const providersSearchParams: NetworkProviderFilters = {
    ...overviewSearchParams,
    order_by: {
      column: searchParams.get('order_by_providers[column]') as 'full_name' | 'organization_name',
      dir: searchParams.get('order_by_providers[dir]') === 'asc' ? 'asc' : 'desc'
    },
    page: parseInt(searchParams.get('page_providers') ?? '1', 10)
  };
  const {
    isFetching: isFetchingProviders,
    networkProviders,
    networkProvidersTotalPages,
    updateNetworkProviderFilters
  } = useGetNetworkProviders(providersSearchParams);

  // LOCATIONS
  const locationsSearchParams: NetworkLocationsFilters = {
    ...overviewSearchParams,
    order_by: {
      column: searchParams.get('order_by_locations[column]') as 'provider_count' | 'state',
      dir: searchParams.get('order_by_locations[dir]') === 'desc' ? 'desc' : 'asc'
    },
    page: parseInt(searchParams.get('page_locations') ?? '1', 10)
  };
  const {
    isFetching: isFetchingLocation,
    networkLocations,
    networkLocationsTotalPages,
    updateNetworkLocationFilters
  } = useGetNetworkLocations(locationsSearchParams);

  // ORGANIZATION MEMBERS
  const {
    isFetching: isFetchingProviderOrganizations,
    networkOrgMembers: networkOrganizations,
    networkOrgMembersTotalPages,
    updateNetworkOrgMembersFilters
  } = useGetNetworkOrgMembers({
    ...overviewSearchParams,
    order_by: {
      column:
        (searchParams.get('order_by_organizations[column]') as
          | 'bipoc_inclusivity'
          | 'lgbq_inclusivity'
          | 'on_violet'
          | 'tgnc_inclusivity'
          | 'total_providers'
          | 'verified_inclusive'
          | null) ?? 'on_violet',
      dir: searchParams.get('order_by_organizations[dir]') === 'desc' ? 'desc' : 'asc'
    },
    page: parseInt(searchParams.get('page_organizations') ?? '1', 10),
    per_page: 5
  });

  useEffect(() => {
    // refreshes data when filters change, triggered after the URL params are updated
    const benchmarkCommunities = searchParams.get('benchmark_communities[]')?.split(',') ?? [];
    const organizations = searchParams.get('organizations[]')?.split(',') ?? [];
    const specialties = searchParams.get('specialties[]')?.split(',') ?? [];
    const states = searchParams.get('states[]')?.split(',') ?? [];
    const activeProviderOrgs = searchParams.get('org_is_on_violet') === 'true';
    const verifiedInclusive = searchParams.get('verified_inclusive') === 'true';
    const orderByProvidersColumn = searchParams.get('order_by_providers[column]') as
      | 'full_name'
      | 'organization_name';
    const orderByProvidersDir =
      searchParams.get('order_by_providers[dir]') === 'asc' ? 'asc' : 'desc';
    const pageProviders = parseInt(searchParams.get('page_providers') ?? '1', 10);
    const orderByLocationsColumn = searchParams.get('order_by_locations[column]') as
      | 'provider_count'
      | 'state';
    const orderByLocationsDir =
      searchParams.get('order_by_locations[dir]') === 'desc' ? 'desc' : 'asc';
    const pageLocations = parseInt(searchParams.get('page_locations') ?? '1', 10);
    const orderByOrganizationsColumn = searchParams.get('order_by_organizations[column]') as
      | 'bipoc_inclusivity'
      | 'lgbq_inclusivity'
      | 'on_violet'
      | 'tgnc_inclusivity'
      | 'total_providers'
      | 'verified_inclusive';
    const orderByOrganizationsDir =
      searchParams.get('order_by_organizations[dir]') === 'desc' ? 'desc' : 'asc';
    const pageOrganizations = parseInt(searchParams.get('page_organizations') ?? '1', 10);

    updateNetworkOverviewFilters({
      'benchmark_communities[]': benchmarkCommunities,
      org_is_on_violet: activeProviderOrgs ? true : undefined,
      'organizations[]': organizations,
      'specialties[]': specialties,
      'states[]': states,
      verified_inclusive: verifiedInclusive ? true : undefined
    });

    updateNetworkProviderFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByProvidersColumn,
        dir: orderByProvidersDir
      },
      org_is_on_violet: activeProviderOrgs ? true : undefined,
      'organizations[]': organizations,
      page: pageProviders,
      'specialties[]': specialties,
      'states[]': states,
      verified_inclusive: verifiedInclusive ? true : undefined
    });

    updateNetworkLocationFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByLocationsColumn,
        dir: orderByLocationsDir
      },
      org_is_on_violet: activeProviderOrgs ? true : undefined,
      'organizations[]': organizations,
      page: pageLocations,
      'specialties[]': specialties,
      'states[]': states,
      verified_inclusive: verifiedInclusive ? true : undefined
    });

    updateNetworkOrgMembersFilters({
      'benchmark_communities[]': benchmarkCommunities,
      order_by: {
        column: orderByOrganizationsColumn,
        dir: orderByOrganizationsDir
      },
      org_is_on_violet: activeProviderOrgs ? true : undefined,
      'organizations[]': organizations,
      page: pageOrganizations,
      per_page: 5,
      'specialties[]': specialties,
      'states[]': states,
      verified_inclusive: verifiedInclusive ? true : undefined
    });
  }, [
    searchParams,
    updateNetworkLocationFilters,
    updateNetworkOrgMembersFilters,
    updateNetworkOverviewFilters,
    updateNetworkProviderFilters
  ]);

  useEffect(() => {
    // sets values when search params change (for example, when user navigates back)
    setCurrentPageProviders(Number(searchParams.get('page_providers') ?? '1'));
    setSortDescriptorProviders({
      column: searchParams.get('order_by_providers[column]') ?? 'full_name',
      direction:
        searchParams.get('order_by_providers[dir]') === null
          ? 'ascending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_providers[dir]')!
            ] as SortDirection)
    });
    setCurrentPageLocations(Number(searchParams.get('page_locations') ?? '1'));
    setSortDescriptorLocations({
      column: searchParams.get('order_by_locations[column]') ?? 'provider_count',
      direction:
        searchParams.get('order_by_locations[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_locations[dir]')!
            ] as SortDirection)
    });
    setCurrentPageOrganizations(Number(searchParams.get('page_organizations') ?? '1'));
    setSortDescriptorOrganizations({
      column: searchParams.get('order_by_organizations[column]') ?? 'on_violet',
      direction:
        searchParams.get('order_by_organizations[dir]') === null
          ? 'descending'
          : ({ asc: 'ascending', desc: 'descending' }[
              searchParams.get('order_by_organizations[dir]')!
            ] as SortDirection)
    });
    setSelectedStates(new Set(searchParams.get('states[]')?.split(',') ?? []));
    setSelectedOrganizationsFilter(searchParams.get('organizations[]')?.split(',') ?? []);
    setActiveProviderOrgsChecked(searchParams.get('org_is_on_violet') === 'true');
    setVerifiedInclusiveChecked(searchParams.get('verified_inclusive') === 'true');
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
          .flatMap(([key, value]) =>
            value.split(',').map(v => networkSearchToFilters(key, v) as Key)
          )
      )
    );
    draftState.current = {
      benchmark_communities: searchParams.get('benchmark_communities[]')?.split(',') ?? [],
      org_is_on_violet: searchParams.get('org_is_on_violet') === 'true',
      organizations: searchParams.get('organizations[]')?.split(',') ?? [],
      specialties: searchParams.get('specialties[]')?.split(',') ?? [],
      states: new Set(searchParams.get('states[]')?.split(',') ?? []),
      verified_inclusive: searchParams.get('verified_inclusive') === 'true'
    };
  }, [searchParams]);

  useEffect(() => {
    // sets the organization name when the organization filter changes
    selectedOrganizationsFilter.forEach(id => {
      if (id === null) return;
      loadNetworkOrgMemberName(id, bearerToken).then(name => {
        setOrgName(name);
      });
    });
  }, [selectedOrganizationsFilter, bearerToken]);

  const selectedStatesFirst = (a: (typeof STATES)[0], b: (typeof STATES)[0]) => {
    if (
      Array.from(draftState.current.states).includes(a.abbreviation) &&
      !Array.from(draftState.current.states).includes(b.abbreviation)
    ) {
      return -1;
    } else if (
      Array.from(draftState.current.states).includes(b.abbreviation) &&
      !Array.from(draftState.current.states).includes(a.abbreviation)
    ) {
      return 1;
    }
    return 0;
  };

  const resetPagesToFirst = () => {
    setCurrentPageLocations(1);
    setCurrentPageOrganizations(1);
    setCurrentPageProviders(1);
  };

  const preserveChanges = () => {
    setSelectedStates(new Set(draftState.current.states));
    setStateSearchValue('');
    setSelectedOrganizationsFilter(draftState.current.organizations);

    const stateFilters = Array.from(draftState.current.states).map(
      state => `State:${state.toString()}`
    );
    const communityFilters = draftState.current.benchmark_communities.map(
      community => `Community:${community.toString()}`
    );
    const updatedFilters = stateFilters
      .concat(communityFilters)
      .concat([
        `Organization:${draftState.current.organizations[0]}`,
        `Specialty:${draftState.current.specialties[0]}`,
        `Organizations on Violet:${draftState.current.org_is_on_violet ? 'true' : 'null'}`,
        `Verified Inclusive Organizations:${draftState.current.verified_inclusive ? 'true' : 'null'}`
      ])
      .filter(
        entry =>
          !entry.includes('null') &&
          !entry.includes('undefined') &&
          isNonEmptyString(entry.split(':')[1])
      );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    resetPagesToFirst();
    setStateSearchValue('');
    setSelectedStates(new Set());
    setSelectedOrganizationsFilter([]);
    setActiveProviderOrgsChecked(false);
    setVerifiedInclusiveChecked(false);
    draftState.current = {
      benchmark_communities: [],
      org_is_on_violet: false,
      organizations: [],
      specialties: [],
      states: new Set(),
      verified_inclusive: false
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('State:')) {
        const state = keyString.split(':')[1];
        draftState.current.states.delete(state);
        setSelectedStates(new Set(draftState.current.states));
        setStateSearchValue('');
      } else if (keyString.includes('Organization:')) {
        draftState.current.organizations = [];
        setSelectedOrganizationsFilter([]);
      } else if (keyString.includes('Specialty:')) {
        draftState.current.specialties = [];
      } else if (keyString.includes('Community:')) {
        const community = keyString.split(':')[1];
        draftState.current.benchmark_communities = draftState.current.benchmark_communities.filter(
          item => item !== community
        );
      } else if (keyString.includes('Active provider org:')) {
        draftState.current.org_is_on_violet = false;
        setActiveProviderOrgsChecked(false);
      } else if (keyString.includes('Verified Inclusive Organizations:')) {
        draftState.current.verified_inclusive = false;
        setVerifiedInclusiveChecked(false);
      }
    });
    resetPagesToFirst();
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    const finalParams = filtersObjectToParams(updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by_providers[column]', sortDescriptorProviders.column?.toString() ?? 'full_name'],
      [
        'order_by_providers[dir]',
        sortDescriptorProviders.direction === 'descending' ? 'desc' : 'asc'
      ],
      [
        'order_by_locations[column]',
        sortDescriptorLocations.column?.toString() ?? 'provider_count'
      ],
      [
        'order_by_locations[dir]',
        sortDescriptorLocations.direction === 'ascending' ? 'asc' : 'desc'
      ],
      [
        'order_by_organizations[column]',
        sortDescriptorOrganizations.column?.toString() ?? 'on_violet'
      ],
      [
        'order_by_organizations[dir]',
        sortDescriptorOrganizations.direction === 'ascending' ? 'asc' : 'desc'
      ],
      ['page_providers', currentPageProviders.toString()],
      ['page_locations', currentPageLocations.toString()],
      ['page_organizations', currentPageOrganizations.toString()]
    ];
    const sortedParams = sortParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  return (
    <>
      <PageTitle
        description="Violet aggregates individual provider Benchmarks to deliver a comprehensive assessment of inclusivity for entire provider organizations. Use the below fields to search and filter for specific care delivery organizations or health systems in your network."
        title="Inclusivity"
        titleVariant="h1"
      />

      <S.FiltersAndActionsWrapper>
        <FilterDropdown>
          <FilterDropdown.Filters onApplyChanges={preserveChanges}>
            <MultiComboBox
              aria-label="Search state(s)"
              data-cy="states-filter"
              filter={contains}
              icon={SearchIcon}
              inputValue={stateSearchValue}
              onInputChange={handleStateSearchChange}
              onSelectionChange={handleSelectedStatesChange}
              placeholder={
                Array.from(draftState.current.states).length > 0
                  ? Array.from(draftState.current.states).join(', ')
                  : 'State'
              }
              selectedKeys={draftState.current.states}
            >
              {[
                <Item
                  key="all"
                  textValue="all"
                >
                  <S.AllOption>All states</S.AllOption>
                </Item>
              ].concat(
                STATES.sort(selectedStatesFirst).map(state => (
                  <Item key={state.abbreviation}>{state.name}</Item>
                ))
              )}
            </MultiComboBox>
            <NetworkOrganizationFilter
              aria-label="Search by provider organization"
              data-cy="organizations-filter"
              handleSelectionChange={handleOrganizationsFilterChange as (value: Key | null) => void}
              placeholder="Provider organization"
              selectedKey={draftState.current.organizations[0] as Key | undefined}
            />
            <S.WideComboBox
              allowsCustomValue
              aria-label="Search by specialty"
              data-cy="specialties-filter"
              filter={contains}
              icon={SearchIcon}
              onSelectionChange={handleSpecialtiesFilterChange}
              placeholder="Specialty"
              selectedKey={draftState.current.specialties[0]}
            >
              {NPI_SPECIALTIES.map(specialty => (
                <Item key={specialty}>{specialty}</Item>
              ))}
            </S.WideComboBox>
            <CommunityFilter filterLabelText="Community Benchmark">
              <CheckboxGroup
                aria-labelledby="filter-label"
                defaultValue={draftState.current.benchmark_communities}
                direction="horizontal"
                onChange={handleFilterByCommunityChange}
              >
                <GroupCheckbox
                  data-cy="bipoc-filter"
                  value="bipoc"
                >
                  BIPOC
                </GroupCheckbox>
                <GroupCheckbox
                  data-cy="lgbq-filter"
                  value="lgbq"
                >
                  LGBQ
                </GroupCheckbox>
                <GroupCheckbox
                  data-cy="tgnc-filter"
                  value="tgnc"
                >
                  TGNC
                </GroupCheckbox>
              </CheckboxGroup>
            </CommunityFilter>
            <S.OnVioletWrapper>
              <S.CheckboxWrapper>
                <Checkbox
                  data-cy="organization-on-violet-filter"
                  isSelected={activeProviderOrgsChecked}
                  onChange={handleActiveProviderOrgsFilterChange}
                >
                  Organizations on Violet
                </Checkbox>
                {isVioEnabled === true && (
                  <Checkbox
                    data-cy="verified-inclusive-organizations-filter"
                    isSelected={verifiedInclusiveChecked}
                    onChange={handleVerifiedInclusiveFilterChange}
                  >
                    Verified Inclusive Organizations
                  </Checkbox>
                )}
              </S.CheckboxWrapper>
            </S.OnVioletWrapper>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              let value = key.toString().split(':')[1];
              if (label.toLowerCase().includes('community')) {
                value = value.toUpperCase();
              }
              return (
                <Item key={key}>
                  {label}
                  {value !== 'true' && (
                    <>
                      {': '}
                      {label.toLowerCase().includes('organization') ? (
                        orgName !== null ? (
                          orgName
                        ) : (
                          <S.SmallSpinner />
                        )
                      ) : label === 'Range' ? (
                        capitalize(value.replaceAll('_', ' '))
                      ) : (
                        value
                      )}
                    </>
                  )}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>
      </S.FiltersAndActionsWrapper>

      {isFetchingOverview ? (
        <Spinner withWrapper />
      ) : (
        <NetworkOverviewSection networkOverview={networkOverview} />
      )}

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showServiceArea}
          onPress={() => setShowServiceArea(!showServiceArea)}
        >
          {showServiceArea ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showServiceArea}>
            <S.ExpandCollapseTitle>Service areas</S.ExpandCollapseTitle>
            {showServiceArea && (
              <S.ExpandCollapseDescription>
                This table displays the density of inclusive providers by geographic service area.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showServiceArea &&
          (isFetchingLocation ? (
            <Spinner withWrapper />
          ) : networkLocations && networkLocations.length > 0 ? (
            <ServiceAreasTable
              currentPageLocations={currentPageLocations}
              handleLocationsTableSortChange={handleLocationsTableSortChange}
              handleSetPageLocations={handleSetPageLocations}
              networkLocations={networkLocations}
              networkLocationsTotalPages={networkLocationsTotalPages}
              sortDescriptorLocations={sortDescriptorLocations}
            />
          ) : (
            <S.EmptyState>No service areas found.</S.EmptyState>
          ))}
      </S.TableWrapper>

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showProviderOrganizations}
          onPress={() => setShowProviderOrganizations(!showProviderOrganizations)}
        >
          {showProviderOrganizations ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showProviderOrganizations}>
            <S.ExpandCollapseTitle>Provider organizations</S.ExpandCollapseTitle>
            {showProviderOrganizations && (
              <S.ExpandCollapseDescription>
                This table displays provider organizations within your network.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showProviderOrganizations &&
          (isFetchingProviderOrganizations ? (
            <Spinner withWrapper />
          ) : networkOrganizations && networkOrganizations.length > 0 ? (
            <NetworkMembersTable
              currentPageOrganizations={currentPageOrganizations}
              handleOrganizationsTableSortChange={handleOrganizationsTableSortChange}
              handleSetPageOrganizations={handleSetPageOrganizations}
              networkOrgMembers={networkOrganizations}
              networkOrganizationsTotalPages={networkOrgMembersTotalPages}
              sortDescriptorOrganizations={sortDescriptorOrganizations}
            />
          ) : (
            <S.EmptyState>No provider organizations found.</S.EmptyState>
          ))}
      </S.TableWrapper>

      <S.TableWrapper>
        <S.ExpandCollapseButton
          $isExpanded={showProviders}
          onPress={() => setShowProviders(!showProviders)}
        >
          {showProviders ? <ShowLessIcon /> : <ShowMoreIcon />}
          <S.ExpandCollapseTextWrapper $isExpanded={showProviders}>
            <S.ExpandCollapseTitle>Providers</S.ExpandCollapseTitle>
            {showProviders && (
              <S.ExpandCollapseDescription>
                This table displays providers within your network and the Benchmarks they have
                achieved.
              </S.ExpandCollapseDescription>
            )}
          </S.ExpandCollapseTextWrapper>
        </S.ExpandCollapseButton>
        {showProviders &&
          (isFetchingProviders ? (
            <Spinner withWrapper />
          ) : networkProviders && networkProviders.length > 0 ? (
            <NetworkProvidersTable
              currentPageProviders={currentPageProviders}
              handleProvidersTableSortChange={handleProvidersTableSortChange}
              handleSetPageProviders={handleSetPageProviders}
              networkProviders={networkProviders}
              networkProvidersTotalPages={networkProvidersTotalPages}
              sortDescriptorProviders={sortDescriptorProviders}
            />
          ) : (
            <S.EmptyState>No providers found.</S.EmptyState>
          ))}
      </S.TableWrapper>
    </>
  );
};

export default Inclusivity;
