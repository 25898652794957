import { ReactComponent as AddCircleIcon } from '@material-design-icons/svg/round/add_circle.svg';
import { useState } from 'react';
import { VisuallyHidden } from 'react-aria';
import Spinner from 'src/components/Spinner';
import useUser from 'src/hooks/useUser';
import useGetNonClinicalExperiences from 'src/pages/Dashboard/hooks/useGetNonClinicalExperiences';
import { NonClinicalExperience } from 'src/pages/Dashboard/utils';
import { useUpdateEffect } from 'usehooks-ts';

import NonClinicalExperienceRow from './NonClinicalExperienceRow';
import * as S from './styles';
import useNonClinicalExperiences, { revalidateNonClinicalRows } from './useNonClinicalExperiences';
import WhatNonClinicalExperienceToAddPopover from './WhatNonClinicalExperienceToAddPopover';

interface Props {
  hasAttemptedSubmit?: boolean;
}

const NonClinicalExperienceTable = ({ hasAttemptedSubmit = false }: Props) => {
  const { user } = useUser();
  const { isFetching, nonClinicalExperiences } = useGetNonClinicalExperiences();
  const {
    draftNonClinicalExperiences,
    invalidRowIndices,
    setDraftNonClinicalExperiences,
    setInvalidRowIndices,
    setOriginalNonClinicalExperiences
  } = useNonClinicalExperiences();

  const [expandedRowIndex, setExpandedRowIndex] = useState<number>(-1);

  const handleExpandCollapseClick = (index: number) => {
    setExpandedRowIndex(prevState => (prevState === index ? -1 : index));
  };

  const handleAddExperienceButtonPress = () => {
    const prevLength = draftNonClinicalExperiences.length;
    setDraftNonClinicalExperiences(prevState => [
      ...prevState,
      {
        bipoc: false,
        category: undefined,
        createdAt: '',
        current: false,
        description: '',
        endYear: undefined,
        id: '',
        lgbq: false,
        organization: '',
        role: '',
        startYear: undefined,
        tgnc: false,
        updatedAt: '',
        userId: user.id
      } as NonClinicalExperience
    ]);
    setExpandedRowIndex(prevLength === 0 ? 0 : prevLength);
  };

  const removeNonClinicalExperience = (index: number) => {
    setDraftNonClinicalExperiences(prevState => prevState.filter((_, i) => i !== index));
    if (expandedRowIndex === index) {
      setExpandedRowIndex(-1);
    }
  };

  const updateNonClinicalExperience = (
    index: number,
    updatedNonClinicalExperience: NonClinicalExperience
  ) => {
    setDraftNonClinicalExperiences(prevState =>
      prevState.map((nonClinicalExperience, i) =>
        i === index ? updatedNonClinicalExperience : nonClinicalExperience
      )
    );
  };

  useUpdateEffect(() => {
    setDraftNonClinicalExperiences(nonClinicalExperiences ?? []);
    setOriginalNonClinicalExperiences(nonClinicalExperiences ?? []);
  }, [nonClinicalExperiences]);

  useUpdateEffect(() => {
    revalidateRows();
  }, [draftNonClinicalExperiences]);

  const revalidateRows = () => {
    const invalidRows = revalidateNonClinicalRows(draftNonClinicalExperiences);
    setInvalidRowIndices(invalidRows);
  };

  return isFetching ? (
    <Spinner withWrapper />
  ) : (
    <>
      <S.EditableNonClinicalExperienceTable
        aria-label="Education experience table"
        data-cy="non-clinical-experience-table"
        emptyMessage="You have not added any additional experience."
        expandedRowIndex={expandedRowIndex}
        hasAttemptedSubmit={hasAttemptedSubmit}
        invalidRowIndices={invalidRowIndices}
      >
        <thead>
          <tr>
            <th>
              <VisuallyHidden>EXPAND / COLLAPSE</VisuallyHidden>
            </th>
            <th>TYPE</th>
            <th>ROLE</th>
            <th>ORGANIZATION</th>
            <th>
              <S.CenterText>CURRENT ROLE</S.CenterText>
            </th>
            <th>START / END YEAR</th>
            <th>
              <S.CenterText>ACTIONS</S.CenterText>
            </th>
            <th>
              <VisuallyHidden>EXPANDED CONTENT</VisuallyHidden>
            </th>
          </tr>
        </thead>
        <tbody>
          {draftNonClinicalExperiences.length > 0
            ? draftNonClinicalExperiences.map((nonClinicalExperience, index) => (
                <NonClinicalExperienceRow
                  key={index}
                  data-cy="non-clinical-experience-row"
                  expandedRowIndex={expandedRowIndex}
                  handleExpandCollapseClick={handleExpandCollapseClick}
                  index={index}
                  isInvalid={hasAttemptedSubmit && invalidRowIndices.includes(index)}
                  nonClinicalExperience={nonClinicalExperience}
                  onRemove={removeNonClinicalExperience}
                  updateNonClinicalExperience={updateNonClinicalExperience}
                />
              ))
            : null}
        </tbody>
      </S.EditableNonClinicalExperienceTable>
      {user.is_clinical ? (
        <S.ClinicalActions>
          <S.AddEducationButton
            data-cy="add-experience-button"
            onPress={handleAddExperienceButtonPress}
            trailingIcon={AddCircleIcon}
            variant="plain"
          >
            Add experience
          </S.AddEducationButton>
          <WhatNonClinicalExperienceToAddPopover />
        </S.ClinicalActions>
      ) : (
        <S.Spacer />
      )}
    </>
  );
};

export default NonClinicalExperienceTable;
