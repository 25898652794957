import HelpPopoverTrigger from 'src/components/HelpPopoverTrigger';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import DataCard from '../DataCard';
import ReportCard from '../ReportCard';

export const DataHighlightDescriptionText = styled.div`
  ${TEXT_STYLES.tagLarge}
  color: var(--gray-800);
  display: flex;
  align-items: center;
`;

export const LargeDataHighlightDescriptionText = styled.div`
  ${TEXT_STYLES.buttonTextSmallScreenLarge}
  color: var(--gray-800);
`;

export const LargeNumberHighlight = styled.div`
  ${TEXT_STYLES.headlineStandardLevel2BoldLarge}
  color: var(--purple-400);
  line-height: 120%;
`;

export const ParenthesesText = styled.tspan``;

// eslint-disable-next-line sort-exports/sort-exports
export const NumberHighlight = styled.div`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);

  & ${ParenthesesText} {
    color: var(--gray-600);
    ${TEXT_STYLES.ctaBaseSmall}
  }
`;

export const SingleDataCard = styled(DataCard)`
  padding: 1rem;
`;

export const SingleReportDataCard = styled(ReportCard)`
  row-gap: 0.375rem;
`;

export const Tooltip = styled(HelpPopoverTrigger)``;
