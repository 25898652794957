import { useCallback, useEffect, useState } from 'react';
import { useIsFirstRender } from 'usehooks-ts';

import { generateOrganizationOverview, OrganizationOverview } from '../utils';

import useApiRequest from './useApiRequest';

type FetchOrganizationOverview = (skipSettingIsFetching?: boolean) => Promise<void>;

type RefetchOrganizationOverview = FetchOrganizationOverview;

interface UseGetOrganizationOverview {
  (organizationId: string): {
    isFetching: boolean;
    organizationOverview: OrganizationOverview | undefined;
    refetchOrganizationOverview: RefetchOrganizationOverview;
  };
}

const useGetOrganizationOverview: UseGetOrganizationOverview = organizationId => {
  const isFirstRender = useIsFirstRender();
  const [organizationOverview, setOrganizationOverview] = useState<
    OrganizationOverview | undefined
  >();
  const [isFetching, setIsFetching] = useState(false);
  const { getRequest, reportError } = useApiRequest();

  const fetchOrganizationOverview: FetchOrganizationOverview = useCallback(
    async (skipSettingIsFetching = false) => {
      if (!organizationId) return;

      if (!skipSettingIsFetching) {
        setIsFetching(true);
      }

      const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/organizations/${organizationId}/users/overview`;

      try {
        const { data } = (await getRequest(url)) as { data?: APIOrganizationOverview['data'] };
        setOrganizationOverview(
          data !== undefined ? generateOrganizationOverview(data) : undefined
        );
      } catch (error) {
        reportError(error);
      }

      if (!skipSettingIsFetching) {
        setIsFetching(false);
      }
    },
    [organizationId, getRequest, reportError]
  );

  useEffect(() => {
    if (!isFirstRender) return;
    (async () => {
      await fetchOrganizationOverview();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrganizationOverview]);

  const refetchOrganizationOverview: RefetchOrganizationOverview = useCallback(
    async (skipSettingIsFetching = false) => {
      await fetchOrganizationOverview(skipSettingIsFetching);
    },
    [fetchOrganizationOverview]
  );

  return {
    isFetching,
    organizationOverview,
    refetchOrganizationOverview
  };
};

export default useGetOrganizationOverview;
