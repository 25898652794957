import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
import capitalize from 'lodash/capitalize';
import { Key, useEffect, useRef, useState } from 'react';
import { AriaComboBoxProps, useFilter } from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import { Item } from 'react-stately';
import Alert from 'src/components/Alert';
import FilterDropdown from 'src/components/FilterDropdown';
import ComboBox from 'src/components/FormFields/ComboBox';
import PageTitle from 'src/components/PageTitle';
import DemographicGraph from 'src/components/Reporting/DemographicGraph';
import Spinner from 'src/components/Spinner';
import TagGroup from 'src/components/TagGroup';
import useUser from 'src/hooks/useUser';
import { NPI_SPECIALTIES } from 'src/pages/constants';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { loadNetworkOrgMemberName } from 'src/utils/loadNetworkOrgMemberName';

import useGetNetworkDiversity from '../../hooks/useGetNetworkDiversity';
import { NetworkOrganizationMember } from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';
import { filtersObjectToParams, networkSearchToFilters, sortParams } from '../networkFilters';

import * as S from './styles';

const Demographics = () => {
  const { bearerToken } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const { contains } = useFilter({ sensitivity: 'base' });
  const [orgName, setOrgName] = useState<string | null>(null);
  const draftState = useRef<{
    organizations: string[];
    specialties: string[];
  }>({
    organizations: searchParams.get('organizations[]')?.split(',') ?? [],
    specialties: searchParams.get('specialties[]')?.split(',') ?? []
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(([key]) => !key.includes('order_by') && !key.includes('page'))
        .map(([key, value]) => networkSearchToFilters(key, value) as Key)
    )
  );

  const [selectedOrganizationsFilter, setSelectedOrganizationsFilter] = useState<string[]>(
    searchParams.get('organizations[]')?.split(',') ?? []
  );

  const {
    isFetching: isFetchingNetworkDiversity,
    networkDiversity,
    updateNetworkDiversityFilters
  } = useGetNetworkDiversity({
    'organizations[]': searchParams.get('organizations[]')?.split(',') ?? undefined,
    'specialties[]': searchParams.get('specialties[]')?.split(',') ?? undefined
  });

  const handleSpecialtiesFilterChange: AriaComboBoxProps<string>['onSelectionChange'] = value => {
    draftState.current.specialties = [value as string];
  };

  const handleOrganizationsFilterChange: AriaComboBoxProps<
    NetworkOrganizationMember['id']
  >['onSelectionChange'] = value => {
    draftState.current.organizations =
      value !== null && value.toString().trim() !== '' ? [value as string] : [];
  };
  const preserveChanges = () => {
    setSelectedOrganizationsFilter(draftState.current.organizations);

    const updatedFilters = [
      `Organization:${draftState.current.organizations[0]}`,
      `Specialty:${draftState.current.specialties[0]}`
    ].filter(
      entry =>
        !entry.includes('null') &&
        !entry.includes('undefined') &&
        isNonEmptyString(entry.split(':')[1])
    );
    setAppliedFilters(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setSelectedOrganizationsFilter([]);
    draftState.current = {
      organizations: [],
      specialties: []
    };
    setAppliedFilters(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Organization:')) {
        draftState.current.organizations = [];
        setSelectedOrganizationsFilter([]);
      } else if (keyString.includes('Specialty:')) {
        draftState.current.specialties = [];
      }
    });
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
  };

  useEffect(() => {
    // sets the organization name when the organization filter changes
    selectedOrganizationsFilter.forEach(id => {
      loadNetworkOrgMemberName(id, bearerToken).then(name => {
        setOrgName(name);
      });
    });
  }, [selectedOrganizationsFilter, bearerToken]);

  useEffect(() => {
    const finalParams = filtersObjectToParams(appliedFilters);
    setSearchParams(sortParams(finalParams));
  }, [appliedFilters, setSearchParams]);

  useEffect(() => {
    updateNetworkDiversityFilters({
      'organizations[]': searchParams.get('organizations[]')?.split(',') ?? undefined,
      'specialties[]': searchParams.get('specialties[]')?.split(',') ?? undefined
    });
  }, [searchParams, updateNetworkDiversityFilters]);

  return (
    <>
      <PageTitle
        description="Network demographic data is shown in an aggregated format to ensure provider privacy.  This information is only available for active providers who have voluntarily submitted profile information onto Violet's platform, enabling more accurate REaL (Race, Ethnicity, and Language) and SOGI (Sexual Orientation and Gender Identity) analytics."
        title="Diversity"
        titleVariant="h1"
      />
      <S.Filters>
        <FilterDropdown.Filters onApplyChanges={preserveChanges}>
          <NetworkOrganizationFilter
            aria-label="Search by provider organization"
            data-cy="organizations-filter"
            handleSelectionChange={handleOrganizationsFilterChange as (value: Key | null) => void}
            placeholder="Provider organization"
            selectedKey={draftState.current.organizations[0] as Key}
          />
          <ComboBox
            aria-label="Search by specialty"
            data-cy="specialties-filter"
            filter={contains}
            icon={SearchIcon}
            onSelectionChange={handleSpecialtiesFilterChange}
            placeholder="Specialty"
            selectedKey={draftState.current.specialties[0]}
          >
            {NPI_SPECIALTIES.map(specialty => (
              <Item key={specialty}>{specialty}</Item>
            ))}
          </ComboBox>
        </FilterDropdown.Filters>
        <FilterDropdown.Tags
          onClear={handleClearFilters}
          onRemove={handleRemoveFilter}
        >
          {Array.from(appliedFilters).map(key => {
            const label = key.toString().split(':')[0];
            let value = key.toString().split(':')[1];
            if (label.toLowerCase().includes('community')) {
              value = value.toUpperCase();
            }
            return (
              <Item key={key}>
                {label}:{' '}
                {label.toLowerCase().includes('organization') ? (
                  orgName !== null ? (
                    orgName
                  ) : (
                    <S.SmallSpinner />
                  )
                ) : label === 'Range' ? (
                  capitalize(value.replaceAll('_', ' '))
                ) : (
                  value
                )}
              </Item>
            );
          })}
        </FilterDropdown.Tags>
      </S.Filters>
      {isFetchingNetworkDiversity ? (
        <Spinner withWrapper />
      ) : (
        <S.ChartsContainer>
          <S.ChartWrapper data-cy="race-ethnicity-data">
            <S.ChartTitle>Race & ethnicity</S.ChartTitle>
            {networkDiversity ? (
              <DemographicGraph
                data={networkDiversity.raceEthnicity}
                type="raceEthnicity"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-race-ethnicity-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartWrapper>
          <S.ChartWrapper data-cy="gender-identity-data">
            <S.ChartTitle>Gender identity</S.ChartTitle>
            {networkDiversity ? (
              <DemographicGraph
                data={networkDiversity.genderIdentity}
                type="genderIdentity"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-gender-identity-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartWrapper>
          <S.ChartWrapper data-cy="sexual-orientation-data">
            <S.ChartTitle>Sexual orientation</S.ChartTitle>
            {networkDiversity ? (
              <DemographicGraph
                data={networkDiversity.sexualOrientation}
                type="sexualOrientation"
              />
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-sexual-orientation-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartWrapper>
          <S.ChartWrapper>
            <S.ChartTitle>Care delivery languages</S.ChartTitle>
            {networkDiversity ? (
              networkDiversity.language.length > 0 ? (
                <>
                  <S.LanguageDescription id="language-description">
                    The percentage of providers fluent in each of the following languages:
                  </S.LanguageDescription>
                  <TagGroup
                    aria-labelledby="language-description"
                    data-cy="languages-data"
                    variant="light"
                  >
                    {networkDiversity.language.map(item => (
                      <Item
                        key={item.label}
                        textValue={`${item.percentage}% ${item.label}`}
                      >
                        <S.LanguagePercentage>{item.percentage}%</S.LanguagePercentage>
                        {item.label}
                      </Item>
                    ))}
                  </TagGroup>
                </>
              ) : (
                <S.LanguageDescription data-cy="languages-data">
                  No known team members speak a language other than English.
                </S.LanguageDescription>
              )
            ) : (
              <Alert
                data-cy="no-data"
                header="No data available."
                id="demographics-languages-no-data-alert"
                isBlock
                isDismissable={false}
                type="info"
              >
                Expand your user base to 30 or more to access detailed insights.
              </Alert>
            )}
          </S.ChartWrapper>
        </S.ChartsContainer>
      )}
    </>
  );
};

export default Demographics;
