import { DESKTOP_BREAKPOINT_RANGE, TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

import Button from '../Buttons/Button';
import TagGroup from '../TagGroup';
import UnstyledButton from '../UnstyledButton';

export const AdditionalTagGroup = styled(TagGroup)`
  /* Tag container */
  > div {
    gap: 0.5rem;
    flex-direction: column;
  }

  /* Tag */
  > div > div {
    width: 100%;

    /* Tag content */
    > div {
      justify-content: space-between;
    }
  }
`;

export const ApplyFiltersButton = styled(Button)`
  justify-self: end;
`;

export const FilterDropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  gap: 0.5rem;
`;

export const FiltersPopoverContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  ${DESKTOP_BREAKPOINT_RANGE} {
    min-width: 37.5rem;
  }
`;

export const FilterTagGroup = styled(TagGroup)`
  margin-left: 0.5rem;
`;

export const MoreFiltersButton = styled(UnstyledButton)`
  ${TEXT_STYLES.tagSmall}
  display: flex;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  color: var(--purple-600);
  background-color: var(--light-purple-50);

  .arrow-icon {
    width: 1rem;
    height: 1rem;
    fill: currentColor;
  }

  .arrow-icon-container {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
    border-radius: 100%;
  }

  &.is-focused {
    outline: 2px solid var(--bright-purple-400);
    outline-offset: 1px;
  }

  &.is-hovered .arrow-icon-container {
    background-color: var(--light-purple-300);
  }
`;
