import { RefObject } from 'react';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { ComboBoxState } from 'react-stately';

import Option from './Option';
import * as S from './styles';

interface Props extends AriaListBoxOptions<object> {
  className?: string;
  listBoxRef: RefObject<HTMLUListElement>;
  state: ComboBoxState<object>;
}

const ListBox = ({ className, listBoxRef, selectedKeys, state, ...ariaListBoxProps }: Props) => {
  const { listBoxProps } = useListBox(ariaListBoxProps, state, listBoxRef);

  return (
    <S.List
      {...listBoxProps}
      ref={listBoxRef}
      className={className}
    >
      {Array.from(state.collection).map(item => (
        <Option
          key={item.key}
          isSelected={
            selectedKeys === 'all' ||
            Array.from(selectedKeys as string[]).includes(item.key as string)
          }
          item={item}
          state={state}
        />
      ))}
    </S.List>
  );
};

export default ListBox;
