import { ReactComponent as BaseCheckCircleIcon } from '@material-design-icons/svg/round/check_circle.svg';
import { CSSProperties } from 'react';
import Spinner from 'src/components/Spinner';
import { default as BaseTable } from 'src/components/Table';
import TooltipTrigger from 'src/components/TooltipTrigger';
import UnstyledButton from 'src/components/UnstyledButton';
import { TEXT_STYLES } from 'src/constants';
import styled from 'styled-components';

export const CheckCircleIcon = styled(BaseCheckCircleIcon)`
  fill: var(--success-400);
`;

interface SpinnerWrapperProps {
  $height: CSSProperties['height'];
}

export const CompletionTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid var(--gray-200);
  margin-top: 1rem;
`;

export const FiltersAndToggle = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const SmallSpinner = styled(Spinner)`
  fill: none !important;
`;

export const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
  display: grid;
  place-content: center;
  ${({ $height }) => `height: ${$height};`}
`;

export const Table = styled(BaseTable)`
  margin-top: 1rem;

  table {
    min-width: 65rem;
    width: 100%;
  }

  tr {
    display: grid;
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 2fr) minmax(0, 3fr) 7rem 8rem 4.25rem 3rem 3rem 3rem;
    border-radius: 0.5rem;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    th,
    td {
      padding: 0.75rem 0.5rem;
    }
  }
`;

export const TableDescription = styled.div`
  ${TEXT_STYLES.bodyCopyMedium}
  color: var(--gray-800);
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1rem;
`;

export const TableTitle = styled.h2`
  ${TEXT_STYLES.headlineSectionLevel2Regular}
  color: var(--purple-400);
  margin: 0 0 0.5rem;
`;

export const Tooltip = styled(TooltipTrigger)`
  display: inline;
`;

export const TooltipText = styled.span`
  ${TEXT_STYLES.tooltipText}
`;

export const TruncatedButton = styled(UnstyledButton)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TruncatedDiv = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
