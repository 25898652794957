import { useFeatureFlagEnabled } from 'posthog-js/react';
import CommunityGraphs from 'src/components/Reporting/CommunityGraphs';
import DataCard from 'src/components/Reporting/DataCard';
import DataCardHeader from 'src/components/Reporting/DataCardHeader';
import DataContainer from 'src/components/Reporting/DataContainer';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import { NetworkOverview } from 'src/pages/Dashboard/utils';

interface Props {
  networkOverview?: NetworkOverview;
}

export const NetworkOverviewSection = ({ networkOverview }: Props) => {
  const isVioEnabled = useFeatureFlagEnabled('verified_inclusive_org');
  const numFormatter = Intl.NumberFormat('en', { notation: 'compact' });

  return (
    <DataContainer
      data-cy="network-overview"
      hideBorder
    >
      {isVioEnabled === true ? (
        <DataReportRow layout="quarters">
          <DataHighlight
            data-cy="total-network-providers"
            detailText="Total providers"
            numberHighlight={numFormatter.format(networkOverview?.totalNetworkProviders ?? 0)}
            popoverContent="Total number of clinical providers linked to your network, based on your provided roster or inferred from third-party data. Clinical users include all licensed and pre-licensed providers, as well as interns actively delivering care."
            variant="small"
          />
          <DataHighlight
            data-cy="total-inclusive-providers"
            detailText="Providers with a Benchmark"
            numberHighlight={numFormatter.format(
              networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
            )}
            popoverContent="Includes all providers who have earned a Violet Benchmark, either through claims data analysis or by completing their profile on Violet to achieve Verified Inclusive Provider status."
            variant="small"
          />
          <DataHighlight
            data-cy="total-active-network-providers"
            detailText="Providers on Violet"
            numberHighlight={numFormatter.format(networkOverview?.totalActiveNetworkProviders ?? 0)}
            popoverContent="Total number of providers who have completed their Violet profiles in order to be benchmarked."
            variant="small"
          />
          <DataHighlight
            data-cy="total-vio-network-providers"
            detailText="Verified Inclusive Providers"
            numberHighlight={numFormatter.format(networkOverview?.totalVioNetworkProviders ?? 0)}
            popoverContent={
              <>
                <p>Verified Inclusive Providers are providers within the network who have:</p>
                <ul>
                  <li>Claimed and submitted their Violet profile</li>
                  <li>
                    Achieved an Awareness Benchmark or higher in at least one community (BIPOC,
                    LGBQ, or TGNC)
                  </li>
                </ul>
              </>
            }
            variant="small"
          />
        </DataReportRow>
      ) : (
        <DataReportRow layout="thirds">
          <DataHighlight
            data-cy="total-network-providers"
            detailText="Providers"
            numberHighlight={numFormatter.format(networkOverview?.totalNetworkProviders ?? 0)}
            variant="small"
          />
          <DataHighlight
            data-cy="total-inclusive-providers"
            detailText="Inclusive providers"
            numberHighlight={numFormatter.format(
              networkOverview?.totalNetworkProvidersWithBenchmarks ?? 0
            )}
            variant="small"
          />
          <DataHighlight
            data-cy="total-active-network-providers"
            detailText="On Violet"
            numberHighlight={numFormatter.format(networkOverview?.totalActiveNetworkProviders ?? 0)}
            variant="small"
          />
        </DataReportRow>
      )}
      <DataReportRow layout="single">
        <DataCard>
          <DataCardHeader
            badgeColor="gray"
            badgeText="All providers"
            description="This chart represents providers within the network who have had their cultural competence assessed and benchmarked by Violet. This encompasses both active providers who have directly onboarded onto the platform and non-active providers whose benchmarks are inferred through data analytics."
            title="Cultural competencies"
            titleVariant="h3"
          />
          <CommunityGraphs
            data={networkOverview?.networkInclusivityPercentages}
            dataType="benchmarks"
          />
        </DataCard>
      </DataReportRow>
    </DataContainer>
  );
};
