import { Temporal } from '@js-temporal/polyfill';
import { ReactComponent as ExportIcon } from '@material-design-icons/svg/round/download.svg';
import { ReactComponent as SearchIcon } from '@material-design-icons/svg/round/search.svg';
// TODO: import `Sortable` from `'react-aria'` once it stops throwing a TS error.
import { Sortable } from '@react-types/shared';
import * as Sentry from '@sentry/react';
import capitalize from 'lodash/capitalize';
import { Key, RefObject, useEffect, useRef, useState } from 'react';
import {
  AriaCheckboxGroupProps,
  AriaComboBoxOptions,
  AriaComboBoxProps,
  AriaSearchFieldProps,
  AriaSelectProps,
  AriaSwitchProps,
  useNumberFormatter
} from 'react-aria';
import { useSearchParams } from 'react-router-dom';
import {
  Cell,
  Column,
  Item,
  Row,
  SortDescriptor,
  SortDirection,
  TableBody,
  TableHeader,
  useAsyncList
} from 'react-stately';
import Button from 'src/components/Buttons/Button';
import FilterDropdown from 'src/components/FilterDropdown';
import CheckboxGroup from 'src/components/FormFields/CheckboxGroup';
import GroupCheckbox from 'src/components/FormFields/CheckboxGroup/GroupCheckbox';
import ComboBox from 'src/components/FormFields/ComboBox';
import SearchField from 'src/components/FormFields/SearchField';
import Select from 'src/components/FormFields/Select';
import Switch from 'src/components/FormFields/Switch';
import PageTitle from 'src/components/PageTitle';
import Pagination from 'src/components/Pagination';
import DataHighlight from 'src/components/Reporting/DataHighlight';
import DataReportRow from 'src/components/Reporting/DataReportRow';
import Spinner from 'src/components/Spinner';
import CommunityFilter from 'src/components/Table/Filters/CommunityFilter';
import TableLinkedCell from 'src/components/Table/TableLinkedCell';
import TableNumberCell from 'src/components/Table/TableNumberCell';
import useOpenErrorModalDialog from 'src/hooks/useOpenErrorModalDialog';
import useOpenSignedOutModalDialog from 'src/hooks/useOpenSignedOutModalDialog';
import useUser from 'src/hooks/useUser';
import { COMMUNITIES } from 'src/pages/constants';
import { downloadCsv } from 'src/utils/downloadCsv';
import isNonEmptyString from 'src/utils/isNonEmptyString';
import { loadNetworkOrgMemberName } from 'src/utils/loadNetworkOrgMemberName';

import TableEmptyState from '../../components/TableEmptyState';
import useGetNetworkEducation from '../../hooks/useGetNetworkEducation';
import useGetNetworkEducationOverview, {
  NetworkEducationOverviewFilters
} from '../../hooks/usetGetNetworkEducationOverview';
import {
  Collection,
  EducationalResource,
  generateCollection,
  generateEducationalResource
} from '../../utils';
import NetworkOrganizationFilter from '../Components/NetworkOrganizationFilter';
import { filtersObjectToParams, networkSearchToFilters, sortParams } from '../networkFilters';

import * as S from './styles';

const EducationEngagement = () => {
  const formatter = useNumberFormatter();
  const [searchParams, setSearchParams] = useSearchParams();

  const openErrorModalDialog = useOpenErrorModalDialog();
  const openSignedOutModalDialog = useOpenSignedOutModalDialog();
  const { bearerToken, user } = useUser();
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const fullNameCellRef: RefObject<HTMLDivElement> = useRef(null);
  const organizationNameRef: RefObject<HTMLDivElement> = useRef(null);
  const educationNameRef: RefObject<HTMLDivElement> = useRef(null);
  const publisherNameRef: RefObject<HTMLDivElement> = useRef(null);
  const [orgName, setOrgName] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page') ?? '1'));
  const [isCourseView, setIsCourseView] = useState<boolean>(
    searchParams.get('resource_type') !== null
      ? searchParams.get('resource_type') === 'course'
      : true
  );
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const courseList = useAsyncList({
    async load({ filterText, signal }) {
      const queryString =
        filterText !== '' ? `&${isCourseView ? 'title' : 'name'}=${filterText}` : '';
      const res = await fetch(
        `${process.env.REACT_APP_API_V2_BASE_PATH}/${
          isCourseView ? 'courses' : 'course_collections'
        }?per_page=10${queryString}`,
        { ...fetchOptions, signal }
      );
      const json = (await res.json()) as APICourseCollections | APICourses;

      const resources = isCourseView
        ? (json as APICourses).data.map(generateEducationalResource)
        : (json as APICourseCollections).data.map(fields => generateCollection(fields, user));

      return {
        items: resources
      };
    }
  });

  const [searchedProviderOrganizationId, setSearchedProviderOrganizationId] = useState<Key | null>(
    searchParams.get('organizations[]') ?? null
  );
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: searchParams.get('order_by[column]') ?? undefined,
    direction:
      searchParams.get('order_by[dir]') === null
        ? undefined
        : ({ asc: 'ascending', desc: 'descending' }[
            searchParams.get('order_by[dir]')!
          ] as SortDirection)
  });
  const draftState = useRef<{
    communities: string[];
    date_range: string;
    organizations: (string | null)[];
    resource_name: string;
    user_name: string;
  }>({
    communities: searchParams.get('communities[]')?.split(',') ?? [],
    date_range: searchParams.get('date_range') ?? 'all_time',
    organizations: searchParams.get('organizations[]')?.split(',') ?? [],
    resource_name: searchParams.get('resource_name') ?? '',
    user_name: searchParams.get('user_name') ?? ''
  });
  const [appliedFilters, setAppliedFilters] = useState<Set<Key>>(
    new Set(
      Array.from(searchParams.entries())
        .filter(
          ([key]) =>
            !key.includes('order_by') && !key.includes('page') && !key.includes('resource_type')
        )
        .flatMap(([key, value]) => value.split(',').map(v => networkSearchToFilters(key, v) as Key))
    )
  );

  /**************** HANDLERS ************************/
  const handleDateRangeSelectionChange: AriaSelectProps<object>['onSelectionChange'] = key => {
    draftState.current.date_range = key as string;
  };

  const handleExportCsvButtonPress = async () => {
    setIsExportingCsv(true);

    const exportSearchParams = new URLSearchParams(searchParams);

    exportSearchParams.delete('page');
    const url: RequestInfo = `${process.env.REACT_APP_API_V2_BASE_PATH}/networks/educations/export${
      exportSearchParams.size === 0 ? '' : `?${exportSearchParams}`
    }`;

    try {
      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        if (response.status === 401) {
          openSignedOutModalDialog();
          return;
        } else {
          throw new Error(`${response.status} (${response.statusText})`);
        }
      }

      const { organization } = user.organization_memberships.find(
        membership => membership.member_role === 'network_manager'
      )!;

      const formattedDate = Temporal.Now.plainDateISO().toLocaleString('en-US');
      const organizationName = organization.name.replace(/\s/g, '');

      await downloadCsv(
        response,
        `Violet_EducationEngagement_${organizationName}_${formattedDate}.csv`
      );
    } catch (error) {
      Sentry.captureException(error);
      openErrorModalDialog();
    }

    setIsExportingCsv(false);
  };

  const handleFilterByCommunityChange: AriaCheckboxGroupProps['onChange'] = communities => {
    draftState.current.communities = [...communities].sort();
  };

  const handleResourceNameChange: AriaComboBoxOptions<object>['onSelectionChange'] = key => {
    const item = courseList.getItem(key as string) as Collection | EducationalResource | undefined;
    const value =
      (item as EducationalResource | undefined)?.fullTitle ??
      (item as Collection | undefined)?.title;

    draftState.current.resource_name = value ?? '';
  };

  const handleSearchedProviderNameChange: AriaSearchFieldProps['onChange'] = value => {
    draftState.current.user_name = value;
  };

  const handleSearchedProviderOrganizationIdSelectionChange: AriaComboBoxProps<object>['onSelectionChange'] =
    key => {
      draftState.current.organizations = [key as string];
    };

  const handleTableSortChange: Sortable['onSortChange'] = ({ column, direction }) => {
    const hasChangedColumn = column !== sortDescriptor.column;
    const newDirection = hasChangedColumn ? 'descending' : direction;
    setSortDescriptor({ column, direction: newDirection });

    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      searchParams.set('order_by[column]', column as string);
      searchParams.set('order_by[dir]', { ascending: 'asc', descending: 'desc' }[newDirection!]);
      searchParams.sort();
      return searchParams;
    });
  };

  const setPage = (page: number) => {
    setCurrentPage(page);

    setSearchParams(searchParams => {
      if (page === 1) {
        searchParams.delete('page');
      } else {
        searchParams.set('page', String(page));
      }
      searchParams.sort();
      return searchParams;
    });
  };

  const handleResourceTypeToggling: AriaSwitchProps['onChange'] = value => {
    setIsCourseView(value);
    /* reset all other filters when toggling between course and collection views */
    setCurrentPage(1);
    setSearchedProviderOrganizationId('');
    courseList.setFilterText('');
    setSearchParams(searchParams => {
      if (currentPage > 1) {
        searchParams.delete('page');
      }
      searchParams.delete('organizations[]');
      searchParams.delete('resource_name');
      searchParams.delete('user_name');
      searchParams.delete('communities[]');
      searchParams.delete('date_range');
      searchParams.set('resource_type', value ? 'course' : 'course_collection');
      searchParams.sort();
      return searchParams;
    });
    draftState.current = {
      communities: [],
      date_range: 'all_time',
      organizations: [],
      resource_name: '',
      user_name: ''
    };
    setAppliedFilters(new Set());
  };

  const preserveChanges = () => {
    setSearchedProviderOrganizationId(draftState.current.organizations[0] ?? null);

    const communityFilters = draftState.current.communities.map(
      community => `Community:${community.toString()}`
    );
    const updatedFilters = communityFilters
      .concat([
        `Organization:${draftState.current.organizations[0]}`,
        `Provider:${draftState.current.user_name}`,
        `Name:${draftState.current.resource_name}`,
        `Range:${draftState.current.date_range}`
      ])
      .filter(
        entry =>
          !entry.includes('null') &&
          !entry.includes('undefined') &&
          !entry.includes('all_time') &&
          isNonEmptyString(entry.split(':')[1])
      );
    setAppliedFilters(new Set(updatedFilters));
    refreshURLparams(new Set(updatedFilters));
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setSearchedProviderOrganizationId('');
    courseList.setFilterText('');
    draftState.current = {
      communities: [],
      date_range: 'all_time',
      organizations: [],
      resource_name: '',
      user_name: ''
    };
    setAppliedFilters(new Set());
    refreshURLparams(new Set());
  };

  const handleRemoveFilter = (keys: Set<Key>) => {
    Array.from(keys).forEach(key => {
      const keyString = key.toString();
      if (keyString.includes('Organization:')) {
        draftState.current.organizations = [];
        setSearchedProviderOrganizationId(null);
      } else if (keyString.includes('Community:')) {
        const community = keyString.split(':')[1];
        draftState.current.communities = draftState.current.communities.filter(
          item => item !== community
        );
      } else if (keyString.includes('Provider:')) {
        draftState.current.user_name = '';
      } else if (keyString.includes('Dates:')) {
        draftState.current.date_range = 'all_time';
      } else if (keyString.includes('Name:')) {
        draftState.current.resource_name = '';
      }
    });
    const updatedFilters = new Set(Array.from(appliedFilters).filter(key => !keys.has(key)));
    setAppliedFilters(updatedFilters);
    refreshURLparams(updatedFilters);
  };

  const refreshURLparams = (updatedFilters: Set<Key>) => {
    setCurrentPage(1);
    const finalParams = filtersObjectToParams(updatedFilters);
    const orderAndPageParams: [string, string][] = [
      ['order_by[column]', sortDescriptor.column?.toString() ?? 'user_name'],
      ['order_by[dir]', sortDescriptor.direction === 'descending' ? 'desc' : 'asc'],
      [
        'resource_type',
        searchParams.get('resource_type') === 'course' ? 'course' : 'course_collection'
      ]
    ];
    const sortedParams = sortParams(orderAndPageParams.concat(finalParams));
    setSearchParams(sortedParams);
  };

  useEffect(() => {
    courseList.setFilterText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCourseView]);

  /**************** API CALLS ************************/
  const { educationOverview, isFetchingNetworkEducationOverview, updateEducationOverviewFilters } =
    useGetNetworkEducationOverview({
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
        undefined,
      'organizations[]':
        searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    });

  const {
    education,
    isFetchingNetworkEducation,
    totalNetworkEducationPages,
    updateEducationFilters
  } = useGetNetworkEducation({
    'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
    date_range:
      (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
      undefined,
    order_by: {
      column: searchParams.get('order_by[column]') ?? 'user_name',
      dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
    },
    'organizations[]': searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
    page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1,
    resource_name: searchParams.get('resource_name') ?? undefined,
    resource_type:
      (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ?? 'course',
    user_name: searchParams.get('user_name') ?? undefined
  });

  useEffect(() => {
    const sharedFilters = {
      'communities[]': searchParams.get('communities[]')?.toLowerCase()?.split(',') ?? undefined,
      date_range:
        (searchParams.get('date_range') as NetworkEducationOverviewFilters['date_range']) ??
        undefined,
      'organizations[]':
        searchParams.get('organizations[]')?.toLowerCase()?.split(',') ?? undefined,
      resource_name: searchParams.get('resource_name') ?? undefined,
      resource_type:
        (searchParams.get('resource_type') as 'course_collection' | 'course' | undefined) ??
        'course',
      user_name: searchParams.get('user_name') ?? undefined
    };

    updateEducationFilters({
      ...sharedFilters,
      order_by: {
        column: searchParams.get('order_by[column]') ?? 'user_name',
        dir: (searchParams.get('order_by[dir]') as 'asc' | 'desc' | undefined) ?? 'asc'
      },
      page: searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
    });

    updateEducationOverviewFilters({
      ...sharedFilters
    });
  }, [searchParams, updateEducationFilters, updateEducationOverviewFilters]);

  useEffect(() => {
    setSearchedProviderOrganizationId(searchParams.get('organizations[]') ?? null);
    setAppliedFilters(
      new Set(
        Array.from(searchParams.entries())
          .filter(
            ([key]) =>
              !key.includes('order_by') && !key.includes('page') && !key.includes('resource_type')
          )
          .flatMap(([key, value]) =>
            value.split(',').map(v => networkSearchToFilters(key, v) as Key)
          )
      )
    );
    draftState.current = {
      communities: searchParams.get('communities[]')?.split(',') ?? [],
      date_range: searchParams.get('date_range') ?? 'all_time',
      organizations: searchParams.get('organizations[]')?.split(',') ?? [],
      resource_name: searchParams.get('resource_name') ?? '',
      user_name: searchParams.get('user_name') ?? ''
    };
  }, [searchParams]);

  useEffect(() => {
    // Set initial text from URL params
    courseList.setFilterText(searchParams.get('resource_name') ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // sets the organization name when the organization filter changes
    if (searchedProviderOrganizationId === null) return;
    loadNetworkOrgMemberName(searchedProviderOrganizationId.toString(), bearerToken).then(name => {
      setOrgName(name);
    });
  }, [searchedProviderOrganizationId, bearerToken]);

  return (
    <>
      <PageTitle
        description="Explore the list of currently active providers expanding their cultural competence skills through targeted educational courses on Violet's platform. To view engagement metrics at an organizational level, apply filters based on provider organization."
        title="Education engagement"
        titleVariant="h1"
      />
      <S.FiltersAndToggle>
        <FilterDropdown>
          <FilterDropdown.Filters onApplyChanges={preserveChanges}>
            <ComboBox
              allowsCustomValue
              aria-label={`${isCourseView ? 'Course' : 'Collection'} name`}
              data-cy="search-by-resource-name-field"
              icon={SearchIcon}
              inputValue={courseList.filterText}
              items={courseList.items as Collection[] | EducationalResource[]}
              onInputChange={courseList.setFilterText}
              onSelectionChange={handleResourceNameChange}
              placeholder={`${isCourseView ? 'Course' : 'Collection'} name`}
            >
              {item => (
                <Item
                  key={(item as EducationalResource).id}
                  textValue={(item as EducationalResource).fullTitle || (item as Collection).title}
                >
                  {(item as EducationalResource).fullTitle || (item as Collection).title}
                </Item>
              )}
            </ComboBox>
            <SearchField
              aria-label="Search by provider name"
              data-cy="search-by-provider-name-field"
              defaultValue={draftState.current.user_name}
              onChange={handleSearchedProviderNameChange}
              placeholder="Provider name"
            />
            <NetworkOrganizationFilter
              aria-label="Search by provider organization"
              data-cy="search-by-provider-organization-field"
              handleSelectionChange={
                handleSearchedProviderOrganizationIdSelectionChange as (value: Key | null) => void
              }
              placeholder="Provider organization"
              selectedKey={draftState.current.organizations[0]!}
            />
            <Select
              aria-label="Filter by date range"
              data-cy="filter-by-date-range-field"
              onSelectionChange={handleDateRangeSelectionChange}
              selectedKey={draftState.current.date_range}
            >
              <Item key="last_30_days">Last 30 days</Item>
              <Item key="last_90_days">Last 90 days</Item>
              <Item key="year_to_date">Year to date</Item>
              <Item key="last_12_months">Last 12 months</Item>
              <Item key="all_time">All time</Item>
            </Select>
            <CommunityFilter filterLabelText="Community of focus">
              <CheckboxGroup
                aria-labelledby="filter-label"
                defaultValue={draftState.current.communities}
                direction="horizontal"
                onChange={handleFilterByCommunityChange}
              >
                {COMMUNITIES.map(community => (
                  <GroupCheckbox
                    key={community}
                    data-cy={`community-${community}`}
                    value={community.toLowerCase()}
                  >
                    {community}
                  </GroupCheckbox>
                ))}
              </CheckboxGroup>
            </CommunityFilter>
          </FilterDropdown.Filters>
          <FilterDropdown.Tags
            onClear={handleClearFilters}
            onRemove={handleRemoveFilter}
          >
            {Array.from(appliedFilters).map(key => {
              const label = key.toString().split(':')[0];
              let value = key.toString().split(':')[1];
              if (label.toLowerCase().includes('community')) {
                value = value.toUpperCase();
              }
              return (
                <Item key={key}>
                  {label}:{' '}
                  {label.toLowerCase().includes('organization') ? (
                    orgName !== null ? (
                      orgName
                    ) : (
                      <S.SmallSpinner />
                    )
                  ) : label === 'Range' ? (
                    capitalize(value.replaceAll('_', ' '))
                  ) : (
                    value
                  )}
                </Item>
              );
            })}
          </FilterDropdown.Tags>
        </FilterDropdown>
        <Switch
          aria-label="View collections or courses"
          data-cy="resource-type-toggle"
          isSelected={isCourseView}
          offLabel="Collection view"
          onChange={handleResourceTypeToggling}
          onLabel="Course view"
        />
      </S.FiltersAndToggle>
      {isFetchingNetworkEducation || isFetchingNetworkEducationOverview ? (
        <S.SpinnerWrapper $height="20rem">
          <Spinner />
        </S.SpinnerWrapper>
      ) : education.length === 0 ? (
        <TableEmptyState />
      ) : (
        <>
          <DataReportRow layout={isCourseView ? 'quarters' : 'thirds'}>
            <DataHighlight
              data-cy="network-resources-completed"
              detailText={`${isCourseView ? 'Courses' : 'Collections'} completed`}
              numberHighlight={formatter.format(educationOverview?.educationCompletedCount ?? 0)}
              variant="small"
            />
            <DataHighlight
              data-cy="network-hours-learning"
              detailText="Hours learning"
              numberHighlight={formatter.format(Number(educationOverview?.educationHours ?? 0))}
              variant="small"
            />
            {isCourseView && (
              <DataHighlight
                data-cy="network-average-course-rating"
                detailText="Average course rating"
                isEmptyState={educationOverview?.averageCourseScore! === 0}
                numberHighlight={
                  educationOverview?.averageCourseScore! > 0
                    ? `${educationOverview?.averageCourseScore!}/5`
                    : 'N/A'
                }
                variant="small"
              />
            )}
            <DataHighlight
              data-cy="network-ce-cme-credits-earned"
              detailText="CE/CME credits earned"
              numberHighlight={formatter.format(educationOverview?.ceCreditsEarned ?? 0)}
              variant="small"
            />
          </DataReportRow>

          <S.CompletionTableWrapper>
            <S.TableHeader>
              <div>
                <S.TableTitle>Education completions</S.TableTitle>
                <S.TableDescription>
                  This table displays providers in your organization and the educations they
                  completed.
                </S.TableDescription>
              </div>
              <Button
                aria-label="Export csv of all education engagement history"
                data-cy="export-csv-button"
                isLoading={isExportingCsv}
                onPress={handleExportCsvButtonPress}
                trailingIcon={ExportIcon}
              >
                Export CSV
              </Button>
            </S.TableHeader>
            <div>
              <S.Table
                aria-label="Table of network providers"
                centeredIndexes={[6, 7, 8]}
                data-cy="network-providers-table"
                hasLinkedRows
                onSortChange={handleTableSortChange}
                sortDescriptor={sortDescriptor}
              >
                <TableHeader>
                  <Column
                    key="user_name"
                    allowsSorting
                  >
                    NAME
                  </Column>
                  <Column
                    key="user_organization"
                    allowsSorting
                  >
                    ORGANIZATION
                  </Column>
                  <Column
                    key="resource_name"
                    allowsSorting
                  >
                    {isCourseView ? 'COURSE' : 'COLLECTION'}
                  </Column>
                  <Column>PUBLISHER</Column>
                  <Column
                    key="completed_at"
                    allowsSorting
                  >
                    <TableNumberCell alignRight>COMPLETION DATE</TableNumberCell>
                  </Column>
                  <Column>
                    <TableNumberCell alignRight>HOURS</TableNumberCell>
                  </Column>
                  <Column>BIPOC</Column>
                  <Column>LGBQ</Column>
                  <Column>TGNC</Column>
                </TableHeader>
                <TableBody>
                  {education.map(
                    ({
                      communities,
                      completionDate,
                      currentlyPublished,
                      hours,
                      id,
                      name,
                      organizations,
                      publisher,
                      resourceId,
                      user
                    }) => (
                      <Row
                        key={id}
                        data-cy="education-row"
                      >
                        <Cell>
                          <S.TruncatedDiv ref={fullNameCellRef}>
                            <S.Tooltip
                              content={<S.TooltipText>{user.fullName}</S.TooltipText>}
                              delay={0}
                            >
                              <S.TruncatedButton
                                style={{ maxWidth: fullNameCellRef.current?.clientWidth }}
                              >
                                {user.fullName}
                              </S.TruncatedButton>
                            </S.Tooltip>
                          </S.TruncatedDiv>
                        </Cell>
                        <Cell>
                          <S.TruncatedDiv ref={organizationNameRef}>
                            <S.Tooltip
                              content={
                                <S.TooltipText>
                                  {organizations !== undefined && organizations.length > 0
                                    ? organizations[0].name
                                    : ''}
                                </S.TooltipText>
                              }
                              delay={0}
                            >
                              <S.TruncatedButton
                                style={{ maxWidth: organizationNameRef.current?.clientWidth }}
                              >
                                {organizations !== undefined && organizations.length > 0
                                  ? organizations[0].name
                                  : ''}
                              </S.TruncatedButton>
                            </S.Tooltip>
                          </S.TruncatedDiv>
                        </Cell>
                        <Cell>
                          <S.TruncatedDiv
                            ref={educationNameRef}
                            style={{ maxWidth: educationNameRef.current?.clientWidth }}
                          >
                            <S.Tooltip
                              content={<S.TooltipText>{name}</S.TooltipText>}
                              delay={0}
                            >
                              {currentlyPublished ? (
                                <TableLinkedCell
                                  data-cy="resource-link"
                                  href={`/dashboard/education/${
                                    isCourseView ? `all-education/` : `collections/`
                                  }${resourceId}`}
                                  target="_blank"
                                >
                                  {name}
                                </TableLinkedCell>
                              ) : (
                                <S.TruncatedButton
                                  style={{ maxWidth: educationNameRef.current?.clientWidth }}
                                >
                                  {name}
                                </S.TruncatedButton>
                              )}
                            </S.Tooltip>
                          </S.TruncatedDiv>
                        </Cell>
                        <Cell>
                          <S.TruncatedDiv ref={publisherNameRef}>
                            <S.Tooltip
                              content={<S.TooltipText>{publisher}</S.TooltipText>}
                              delay={0}
                            >
                              <S.TruncatedButton
                                style={{ maxWidth: publisherNameRef.current?.clientWidth }}
                              >
                                {publisher}
                              </S.TruncatedButton>
                            </S.Tooltip>
                          </S.TruncatedDiv>
                        </Cell>
                        <Cell>
                          <TableNumberCell>
                            {completionDate.toLocaleString('en-US')}
                          </TableNumberCell>
                        </Cell>
                        <Cell>
                          <TableNumberCell>{hours}</TableNumberCell>
                        </Cell>
                        <Cell>
                          {communities.includes('bipoc') && (
                            <S.CheckCircleIcon
                              aria-label="Checked"
                              role="img"
                            />
                          )}
                        </Cell>
                        <Cell>
                          {communities.includes('lgbq') && (
                            <S.CheckCircleIcon
                              aria-label="Checked"
                              role="img"
                            />
                          )}
                        </Cell>
                        <Cell>
                          {communities.includes('tgnc') && (
                            <S.CheckCircleIcon
                              aria-label="Checked"
                              role="img"
                            />
                          )}
                        </Cell>
                      </Row>
                    )
                  )}
                </TableBody>
              </S.Table>
              <Pagination
                currentPage={currentPage}
                setPage={setPage}
                totalPages={totalNetworkEducationPages}
              />
            </div>
          </S.CompletionTableWrapper>
        </>
      )}
    </>
  );
};

export default EducationEngagement;
